import React from 'react';
import t from 'prop-types';

import {
  Form,
  Header2,
  InputAddon,
  InputLabel,
  InputRadio,
  InputWrapper,
  InputError,
  InputGroup,
  InputHelp,
  InputNumeric,
} from 'fiducius-ui';

import { Fade } from '../../../routing';
import { useScrollToTopOnMount, safeAccess } from '../../../utils';

import IncomeSourceForm from './income-source-form';

const getAdditionalIncomeSources = (size, incomeSources = [], errors = {}) => {
  let forms = [];
  const existingSize = incomeSources != null ? incomeSources.length : 0;
  for (let i = 0; i < size; i++) {
    let data = {};
    let incomeSourceErrors = {};
    if (i < existingSize) {
      data = incomeSources[i];
      incomeSourceErrors['incomeSource'] = safeAccess(errors, 'incomeSource' + i);
      incomeSourceErrors['annualIncome'] = safeAccess(errors, 'annualIncome' + i);
    }
    forms.push(
      <IncomeSourceForm
        key={`income-source-form-${i}`}
        data={data}
        errors={incomeSourceErrors}
        id={i}
        size={size}
      />
    );
  }

  return forms;
};

const TabIncome = ({ data = {}, errors = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Tell us about your income</Header2>

      <InputWrapper>
        <div className="col-12 col-md-6">
          <InputLabel htmlFor="agi">What is your Adjusted Gross Income?</InputLabel>
          <InputError>{safeAccess(errors, 'agi.detail')}</InputError>
          <InputGroup affordance="large">
            <InputAddon>$</InputAddon>
            <InputNumeric name="agi" step="0.01" defaultValue={data.agi} />
            <InputAddon>.00</InputAddon>
          </InputGroup>
          <InputHelp>
            Please input your adjusted gross income from your most current Federal 1040 tax form
            (Line 8b).
          </InputHelp>
        </div>
      </InputWrapper>

      <InputWrapper>
        <InputLabel>
          Were you temporarily laid off, furloughed, or have you received a decrease in pay within
          the past 12 months?
        </InputLabel>
        <InputRadio
          name="furloughed"
          value="Y"
          label="Yes"
          defaultChecked={data.furloughed === 'Y'}
        />
        <InputRadio
          name="furloughed"
          value="N"
          label="No"
          defaultChecked={data.furloughed === 'N'}
        />
      </InputWrapper>

      <InputWrapper>
        <InputLabel>Do you have additional jobs or secondary income?</InputLabel>
        <InputRadio
          name="haveAdditionalJobs"
          value="Y"
          label="Yes"
          defaultChecked={data.haveAdditionalJobs === 'Y'}
        />
        <InputRadio
          name="haveAdditionalJobs"
          value="N"
          label="No"
          defaultChecked={data.haveAdditionalJobs === 'N'}
        />
      </InputWrapper>
      <Fade id="additionalIncomeGate" show={data.haveAdditionalJobs === 'Y'}>
        <InputWrapper error={!!errors.additionalJobCount}>
          <InputLabel htmlFor="additionalJobCount">
            How many additional income sources do you have?
          </InputLabel>
          <InputError>{safeAccess(errors, 'additionalJobCount.detail')}</InputError>
          <InputGroup affordance="small">
            <InputNumeric
              name="additionalJobCount"
              min={1}
              defaultValue={data.additionalJobCount}
            />
          </InputGroup>
        </InputWrapper>

        {getAdditionalIncomeSources(data.additionalJobCount, data.additionalJobs, errors)}
      </Fade>
    </Form>
  );
};

TabIncome.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabIncome;
