import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Button,
  Lede,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';

import StandardConfirmation from '../components/standard-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';
import StepDisplay from '../components/step-display';
import { MessageTile } from '../../../messages';
import { MessageDiv } from '../../../messages';

class Consolidation extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
  };

  constructor(props) {
    super(props);
    this.state = { showStep1: false, showStep2: false, showStep3: false };
  }

  componentDidMount() {
    const pathName = window.location.pathname;
    if (pathName.includes('/step')) {
      this.setState({
        ...this.state,
        showStep1: pathName.includes('/step-1'),
        showStep2: pathName.includes('/step-2'),
        showStep3: pathName.includes('/step-3'),
      });
    }
  }

  componentDidUpdate() {}

  handleStep1Click = () => {
    this.handleSectionClick('step1');
  };
  handleStep2Click = () => {
    this.handleSectionClick('step2');
  };
  handleStep3Click = () => {
    this.handleSectionClick('step3');
  };

  handleSectionClick = (sectionName) => {
    if (sectionName === 'step1') {
      this.setState(
        {
          showStep1: true,
          showStep2: false,
          showStep3: false,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    } else if (sectionName === 'step2') {
      this.setState(
        {
          showStep1: false,
          showStep2: true,
          showStep3: false,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    } else if (sectionName === 'step3') {
      this.setState(
        {
          showStep1: false,
          showStep2: false,
          showStep3: true,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    }
  };

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  getStep1Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'Consolidation');
    if (empCertStep.stepComplete) {
      retVal = 'success';
    } else {
      if (todos !== undefined && todos !== null) {
        const currentToDo = todos.find((a) => a.id === 103);
        if (currentToDo !== undefined && currentToDo !== null) {
          retVal = 'info';
        } else {
          const nextToDo = todos.find((a) => a.id === 104 || a.id === 105);
          if (nextToDo !== undefined && nextToDo !== null) {
            retVal = 'success';
          }
        }
      }
    }

    return retVal;
  };

  getStep2Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'Consolidation');
    if (empCertStep.stepComplete) {
      retVal = 'success';
    } else {
      if (todos !== undefined && todos !== null) {
        const currentToDo = todos.find((a) => a.id === 104);
        if (currentToDo !== undefined && currentToDo !== null) {
          retVal = 'info';
        } else {
          const nextToDo = todos.find((a) => a.id === 105);
          if (nextToDo !== undefined && nextToDo !== null) {
            retVal = 'success';
          }
        }
      }
    }
    return retVal;
  };

  getStep3Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'Consolidation');
    if (empCertStep.stepComplete) {
      retVal = 'success';
    } else {
      if (todos !== undefined && todos !== null) {
        const currentToDo = todos.find((a) => a.id === 105);
        if (currentToDo !== undefined && currentToDo !== null) {
          retVal = 'info';
        }
      }
    }

    return retVal;
  };

  getStep3RestrictionDate = () => {
    let retVal = undefined;
    const { permissions } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'Consolidation');
    if (empCertStep.stepDateRestriction !== undefined) {
      let stepRestriction = new Date(empCertStep.stepDateRestriction);
      retVal =
        (stepRestriction.getMonth() + 1).toString() +
        '/' +
        stepRestriction.getDate() +
        '/' +
        stepRestriction.getFullYear();
    }
    return retVal;
  };

  render() {
    const { permissions } = this.props;
    const restrictionDate = this.getStep3RestrictionDate();
    return (
      <>
        <ForgivenessProgress />
        <DashboardSection>
          <Card brand="primary">
            <CardBody>
              <CardTitle>Consolidate Loans</CardTitle>
              <CardText>
                <p>Completing this step will align your loans for the program.</p>
                <p>
                  <MessageDiv messageType={'Consolidation_Tab'} />
                </p>
                <div className="row col-12">
                  <div className="col-4">
                    <Lede>
                      <Button brand={this.getStep1Brand()} onClick={this.handleStep1Click}>
                        Generate
                      </Button>
                    </Lede>
                  </div>
                  <div className="col-4">
                    <Lede>
                      <Button brand={this.getStep2Brand()} onClick={this.handleStep2Click}>
                        Submit
                      </Button>
                    </Lede>
                  </div>
                  <div className="col-4">
                    <Lede>
                      <Button brand={this.getStep3Brand()} onClick={this.handleStep3Click}>
                        Review
                      </Button>
                    </Lede>
                  </div>
                </div>
              </CardText>
            </CardBody>
          </Card>
        </DashboardSection>
        {this.state.showStep1 && (
          <div id="step1Section">
            <DashboardSection title="Generate your Consolidation Application">
              <div id="messageSection">
                <MessageTile messageType={'Consolidation_Generate'} />
              </div>
              <Card>
                {permissions.isConsolidationSubmitGeneratePaused && (
                  <>
                    <CardBody overlayCard>
                      <>
                        <CardTitle overlayCard>Consolidation Pause</CardTitle>
                        <CardText overlayCard>
                          <p>
                            Effective <b>April 16, 2024</b>, consolidation applications will be
                            temporarily paused due to updates from the Department of Education in
                            preparation for new initiatives anticipated in <b>July 2024</b>. Please
                            be advised that initiating a consolidation during this period may have
                            negative consequences. Wait for Fiducius to notify you when it is time
                            to consolidate your loans before starting your consolidation.
                          </p>
                        </CardText>
                      </>
                    </CardBody>
                  </>
                )}
                <CardBody disableChildren={permissions.isConsolidationSubmitGeneratePaused}>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 - Sign Consolidation Application'}>
                      <ul>
                        <li>
                          Please click the button below to generate your Consolidation Application.
                        </li>
                        <li>Electronically sign the application.</li>
                        <li>
                          After electronically signing your application, you will be taken to your
                          My Documents page where you can download the file.
                        </li>
                      </ul>
                    </StepDisplay>
                  </AsyncLoader>
                  {!permissions.isConsolidationSubmitGeneratePaused && (
                    <StandardConfirmation stepId={103} />
                  )}
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep2 && (
          <div id="step2Section">
            <DashboardSection title="Submit your Consolidation Application">
              <div id="messageSection">
                <MessageTile messageType={'Consolidation_Submit'} />
              </div>
              <Card>
                {permissions.isConsolidationSubmitGeneratePaused && (
                  <>
                    <CardBody overlayCard>
                      <>
                        <CardTitle overlayCard>Consolidation Pause</CardTitle>
                        <CardText overlayCard>
                          <p>
                            This step <b>can not</b> be completed at this time. Please return to
                            your MyFiducius portal and complete it once you are notified that the
                            consolidation pause is over, projected for <b>July 2024</b>.
                          </p>
                        </CardText>
                      </>
                    </CardBody>
                  </>
                )}
                <CardBody disableChildren={permissions.isConsolidationSubmitGeneratePaused}>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 – Verify Consolidation Application Information'}>
                      <ul>
                        <li>
                          Please verify your Social Security Number is present on all pages up to
                          the signature page.
                        </li>{' '}
                        <li>Please verify that your address on line #9 is not a P.O. box.</li>
                        <li>
                          Please verify that your references at the bottom of page one have
                          different home addresses and phone numbers from both you and each other.{' '}
                        </li>{' '}
                      </ul>
                    </StepDisplay>
                    <StepDisplay title={'Step 2 – Access your loan servicer account(s)'}>
                      <ul>
                        <li>Login to your loan servicer account(s).</li>
                        <li>
                          Verify the number of loans on your consolidation application matches the
                          number of loans with an outstanding balance at your servicer(s).
                        </li>
                      </ul>
                    </StepDisplay>
                    <StepDisplay title={'Step 3 – Confirm the status of your loan(s)'}>
                      <ul>
                        <li>
                          Do not submit your application if your loans appear in the following
                          statuses:
                        </li>
                        <ul>
                          <li>In-school</li>
                          <li>Deferment</li>
                          <li>Default</li>
                          <ul>
                            <li>
                              If your loans are currently in any of these statuses, please contact
                              us.
                            </li>
                          </ul>
                        </ul>
                      </ul>
                    </StepDisplay>
                  </AsyncLoader>
                  {!permissions.isConsolidationSubmitGeneratePaused && (
                    <StandardConfirmation stepId={104} />
                  )}
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep3 && (
          <div id="step3Section">
            <DashboardSection title="Check on your Consolidation Application">
              <div id="messageSection">
                <MessageTile messageType={'Consolidation_Review'} />
              </div>
              <Card
                overlayCard={
                  restrictionDate !== undefined && !permissions.isConsolidationCheckPaused
                }
              >
                {!permissions.isConsolidationCheckPaused && (
                  <CardBody overlayCard hideOverlay={restrictionDate === undefined}>
                    {restrictionDate !== undefined && (
                      <>
                        <CardTitle overlayCard>Wait for Consolidation Completion</CardTitle>
                        <CardText overlayCard>
                          <p>
                            Based on the average processing time for this document, it will be
                            processed by your loan servicer on {restrictionDate} at the earliest*.
                            You will not be able to confirm completion of this step until that date.
                          </p>
                          <p>
                            *Please note: Your document is not guaranteed to be processed by this
                            date. It is important to follow your steps closely.
                          </p>
                        </CardText>
                      </>
                    )}
                  </CardBody>
                )}
                {permissions.isConsolidationCheckPaused && (
                  <>
                    <CardBody overlayCard>
                      <>
                        <CardTitle overlayCard>Consolidation Pause</CardTitle>
                        <CardText overlayCard>
                          <p>
                            This step <b>can not</b> be completed at this time. Please return to
                            your MyFiducius portal and complete it once you are notified that the
                            consolidation pause is over, projected for <b>July 2024</b>.
                          </p>
                        </CardText>
                      </>
                    </CardBody>
                  </>
                )}
                <CardBody
                  disableChildren={
                    restrictionDate !== undefined || permissions.isConsolidationCheckPaused
                  }
                >
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 – Access your MOHELA Servicing account'}>
                      <ul>
                        <li>
                          Create or Login to your account at{' '}
                          <a
                            href="https://www.mohela.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            MOHELA
                          </a>
                        </li>
                        <li>
                          <strong>Please note:</strong> You will be able to create an account with
                          MOHELA Servicing approximately three weeks after the application is
                          submitted.
                        </li>
                      </ul>
                    </StepDisplay>
                    <StepDisplay title={'Step 2 – Check on the status of the consolidation'}>
                      <ul>
                        <li>
                          Check your MOHELA inbox for any correspondence regarding your
                          consolidation.
                        </li>
                        <li>
                          <strong>Please note:</strong> It typically takes{' '}
                          <strong>four to six weeks</strong> for the consolidation to process. The
                          first correspondence is sent to you approximately three weeks after you
                          submit the form.
                        </li>
                        <li>What are you looking for? Examples include:</li>
                        <ul>
                          <li>Consolidation Application Received</li>
                          <li>Consolidation Request Almost Complete</li>
                          <li>We Completed Your Consolidation!</li>
                          <li>
                            <strong>Please note:</strong> The letter may have a different title than
                            the list above. If you have any questions about the letters in your
                            inbox with MOHELA, please submit a Contact Us request.
                          </li>
                        </ul>
                      </ul>
                    </StepDisplay>
                    <StepDisplay title={'Step 3 – Confirm completion'}>
                      <ul>
                        <li>
                          You will receive a letter in your MOHELA inbox confirming the completion
                          of your consolidation. Once received, confirm the number of loans on the
                          letter matches the number of loans on your consolidation application.
                        </li>
                        <li>
                          Confirm the entire loan balance shown on your consolidation application is
                          now at MOHELA.
                        </li>
                        <li>
                          Confirm all loans included in the consolidation have a $0.00 balance at
                          your previous loan servicer(s).
                        </li>
                        <li>
                          <strong>Please Note:</strong> your consolidation application can take up
                          to two months to process.
                        </li>
                      </ul>
                      <strong>
                        Do not confirm this step is done until you have verified the transfer is
                        complete.
                      </strong>
                      <br />
                      If you submitted your consolidation application more than eight weeks ago and
                      did not include all your loans, or your previous loan servicer(s) still show a
                      balance, please submit a Contact Us request on the left side of your portal.
                      We will reply to your request after researching your account.
                    </StepDisplay>
                    {/* contact us at <a href="tel:+15136455400">(513) 645-5400</a> option: 2. */}
                  </AsyncLoader>
                  {!permissions.isConsolidationCheckPaused && <StandardConfirmation stepId={105} />}
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
    state.selfServiceDocument.requests.updateResource,
    state.selfServiceDocument.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Consolidation));
