import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  DashboardSection,
  Header4,
  Lede,
  Button,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';

import { authLoadPermissions } from '../../../auth';

import StandardConfirmation from '../../forgiveness/components/standard-confirmation';
import ReducedIDRProgress from '../components/reduced-idr-progress';

import { todosLoadCollection } from '../../../todos/redux/operations';

import { legalHandleFormChange, legalUpdateResource } from '../../../legal/redux/operations';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1.5rem 0 3rem 0;
`;

const TextLeftList = styled.ul`
  text-align: left;
`;

const TextLeft = styled.p`
  text-align: left;
`;

const AUTH_KEY = 'AUTHIDR';

const ReducedIDRWelcome = ({ request, permissions, consent, load, reload, todos }) => {
  const [redirect, setRedirect] = useState('');
  const [showAuthorizedUse, setShowAuthorizedUse] = useState(false);
  const [authUseCompleted, setAuthUseCompleted] = useState(false);
  const [CurrentReducedIdrStep, setCurrentReducedIdrStep] = useState('');

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (authUseCompleted) {
      // Find where to redirect based on todos
      if (todos !== undefined && todos !== null) {
        const currentToDo = todos.find((a) => a.id === 1600);
        if (currentToDo === undefined || currentToDo === null) {
          setRedirect('/benefits/reducedidr/idr');
        } else {
          setRedirect('/benefits/reducedidr/idrrenewal');
        }
      } else {
        setRedirect('/benefits/reducedidr/idr');
      }
    }
  }, [authUseCompleted]);

  const handleAuthorizedUseClick = () => {
    handleSectionClick('authorizedUse');
  };

  const handleSectionClick = (sectionName) => {
    if (sectionName === 'authorizedUse') {
      setShowAuthorizedUse(true);
    }
  };

  useEffect(() => {
    if (showAuthorizedUse) {
      handleAuthorizedUseScroll();
    }
  }, [showAuthorizedUse]);

  const handleAuthorizedUseScroll = () => {
    document.getElementById('authorizedUseSection').scrollIntoView({ behavior: 'smooth' });
  };

  const Authorize = () => {
    consent().then(() => {
      setAuthUseCompleted(true);
    });

    // consent().then(() => {
    //   setRedirect('/benefits/reducedidr/idr');
    // });
  };

  const checkAuthorizedUseCompleted = () => {
    let retVal = false;

    if (todos !== undefined && todos !== null) {
      const currentToDo = todos.find((a) => a.id === 1100);
      if (currentToDo === undefined || currentToDo === null) {
        retVal = true;
      }
    }
    return retVal;
  };

  if (redirect && redirect.length > 0) {
    return <Redirect to={redirect} />;
  } else {
    return (
      <>
        <div>
          <ReducedIDRProgress />
          <DashboardSection>
            <Card brand="primary">
              <AsyncLoader request={request}>
                <CardBody>
                  <CardTitle>Welcome to Reduced IDR!</CardTitle>
                  <CardText>
                    <p>
                      Congratulations on taking the first step to reduce your loan payment! We have
                      customized step by step instructions to help you achieve it. The first step is
                      complete the Authorized Use Consent below.
                    </p>
                    <div className="row col-12">
                      <div className="col-3">
                        <Lede>
                          <Button
                            brand={checkAuthorizedUseCompleted() ? 'success' : 'info'}
                            onClick={handleAuthorizedUseClick}
                          >
                            Authorized Use Consent
                          </Button>
                        </Lede>
                      </div>
                    </div>
                  </CardText>
                </CardBody>
              </AsyncLoader>
              {checkAuthorizedUseCompleted() && (
                <StandardConfirmation stepId={1100} forReducedIDR={true} />
              )}
            </Card>
          </DashboardSection>
          {showAuthorizedUse && (
            <DashboardSection>
              <AsyncLoader request={request} empty={request.isEmpty ? null : null} loading={null}>
                <Card brand={request.hasFailed ? 'danger' : 'primary'} id="authorizedUseSection">
                  {request.hasFailed && <CardHeader>Saving Authorized Use Failure</CardHeader>}
                  <CardBody>
                    <CardTitle>Authorized Use Consent</CardTitle>
                    <CardText>
                      <Header4>What am I authorizing?</Header4>
                      <TextLeft>Your signature authorizes Fiducius to:</TextLeft>
                      <TextLeftList>
                        <li>
                          Establish the correct repayment plan with The Department of Education for
                          your reduced Income-Driven Repayment (IDR).
                        </li>
                        <li>We will not utilize your FSA ID to access your account.</li>
                      </TextLeftList>
                    </CardText>
                  </CardBody>
                  <ButtonWrapper>
                    {checkAuthorizedUseCompleted() && (
                      <CardBody>
                        <CardText>
                          <p>
                            You've already consented to the items listed above. Please submit a
                            Contact Us request if you would like this changed.
                          </p>
                        </CardText>
                      </CardBody>
                    )}
                    {!checkAuthorizedUseCompleted() && (
                      <Button brand="primary" size="lg" onClick={Authorize}>
                        I agree
                      </Button>
                    )}
                  </ButtonWrapper>
                </Card>
              </AsyncLoader>
            </DashboardSection>
          )}
        </div>
      </>
    );
  }
};

ReducedIDRWelcome.propTypes = {
  request: t.object.isRequired,
  todos: t.array,
  consent: t.func.isRequired,
  reload: t.func.isRequired,
  load: t.func.isRequired,
  permissions: t.object,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.loadCollection,
    state.legal.requests.updateResource,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({
  reload: async () => {
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },

  load: async () => {
    await dispatch(todosLoadCollection());
  },
  consent: async () => {
    await dispatch(legalHandleFormChange({ id: AUTH_KEY, customerAuthorization: true }, []));
    await dispatch(legalUpdateResource(AUTH_KEY, AUTH_KEY));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ReducedIDRWelcome));
