import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, DashboardSection } from 'fiducius-ui';

import { mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

import RenewalConfirmation from '../../forgiveness/components/renewal-confirmation';
import EmpCertProgress from '../components/emp-cert-progress';
import StepDisplay from '../../forgiveness/components/step-display';

class EmpCertGenerate extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { request } = this.props;

    return (
      <>
        <AsyncLoader request={request}>
          <div id="step1Section">
            <EmpCertProgress />
            <DashboardSection title="Generate your Employment Certification">
              <Card>
                <CardBody>
                  <>
                    <AsyncLoader request={this.props.request} loading={<></>}>
                      <StepDisplay
                        title={'Step 1 – Generate and Complete Employment Certification form'}
                      >
                        <ul>
                          <li>
                            Please click the button below to generate your Employment Certification
                            form.
                          </li>
                          <li>
                            After clicking the button, you will be directed to sign the first page
                            of the form and an email will be sent to the authorized official to
                            complete the second page of the form.
                          </li>
                        </ul>
                      </StepDisplay>
                    </AsyncLoader>
                    <RenewalConfirmation stepId={800} />
                  </>
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(EmpCertGenerate));
