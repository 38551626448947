import actions from './actions';
import { RenewalValidator } from './validators';
import { resourceOperationsGenerator } from '../../../utils';

const renewalValidator = new RenewalValidator();

const fixData = (data) => {
  return data.map((resource) => {
    resource.borrowMore = getValueFromNullableBool(resource.borrowMore);
    resource.changedJobs = getValueFromNullableBool(resource.changedJobs);
    resource.clientCurrentlyMarried = resource.clientCurrentlyMarried ? 'Y' : 'N';
    resource.clientMarriageStatusCorrect = 'Y'; // default to Y
    resource.currentHouseholdSizeCorrect = getValueFromNullableBool(
      resource.currentHouseholdSizeCorrect
    );
    resource.currentlyEmployed = getValueFromNullableBool(resource.currentlyEmployed);
    resource.currentlyEmployedFulltime = getValueFromNullableBool(
      resource.currentlyEmployedFulltime
    );
    resource.currentlyEngaged = getValueFromNullableBool(resource.currentlyEngaged);
    resource.haveAdditionalJobs = getValueFromNullableBool(resource.haveAdditionalJobs);
    resource.incomeChanged = getValueFromNullableBool(resource.incomeChanged);
    resource.furloughed = getValueFromNullableBool(resource.furloughed);
    resource.newJobNonProfit = getValueFromNullableBool(resource.newJobNonProfit);
    resource.recentOrPlannedNameChange = getValueFromNullableBool(
      resource.recentOrPlannedNameChange
    );
    resource.returnToSchool = getValueFromNullableBool(resource.returnToSchool);
    resource.spouseHasStudentLoans = getValueFromNullableBool(resource.spouseHasStudentLoans);

    resource['initialMarriageStatus'] = 'single';
    if (resource.clientCurrentlyMarried === 'Y') {
      resource['initialMarriageStatus'] = 'married';
    } else if (resource.currentlyEngaged === 'Y') {
      resource['initialMarriageStatus'] = 'engaged';
    }

    resource['initialHouseholdSize'] = resource.currentHouseholdSize - 1;
    resource['householdSize'] = resource.initialHouseholdSize;
    if (resource.householdSize > 0 && resource.dependents) {
      for (let i = 0; i < resource.dependents.length; i++) {
        resource['dependentName' + i] = resource.dependents[i].dependentName;
        resource['dependentLastName' + i] = resource.dependents[i].dependentLastName;
        resource['dependentRelationship' + i] = resource.dependents[i].dependentRelationship;
        resource['dependentBirthdate' + i] = resource.dependents[i].dependentBirthdate;
      }
    }

    resource['initialEmployerSize'] = resource.employers != null ? resource.employers.length : 0;
    resource['employersCount'] = resource.initialEmployerSize;

    if (resource.initialEmployerSize > 0) {
      for (let i = 0; i < resource.initialEmployerSize; i++) {
        resource['employmentEmployerName' + i] = resource.employers[i].employmentEmployerName;
        resource['employmentJobTitle' + i] = resource.employers[i].employmentJobTitle;
        resource['employmentStartDate' + i] = resource.employers[i].employmentStartDate;
        resource['employmentEndDate' + i] = resource.employers[i].employmentEndDate;
        resource['employmentEin' + i] = resource.employers[i].employmentEin;
        resource['employmentPayPeriods' + i] = resource.employers[i].employmentPayPeriods;
        resource['employmentHours' + i] = resource.employers[i].employmentHours;
        resource['employmentAddress1' + i] = resource.employers[i].employmentAddress1;
        resource['employmentAddress2' + i] = resource.employers[i].employmentAddress2;
        resource['employmentCity' + i] = resource.employers[i].employmentCity;
        resource['employmentState' + i] = resource.employers[i].employmentState;
        resource['employmentZip' + i] = resource.employers[i].employmentZip;
        resource['employmentEmploymentClassification' + i] =
          resource.employers[i].employmentEmploymentClassification;
        resource['employmentEmploymentType' + i] = resource.employers[i].employmentEmploymentType;
      }
    }

    if (resource.haveAdditionalJobs === 'Y' && resource.additionalJobs) {
      resource.additionalJobCount = resource.additionalJobs.length;
      for (let i = 0; i < resource.additionalJobCount; i++) {
        resource['incomeSource' + i] = resource.additionalJobs[i].incomeSource;
        resource['annualIncome' + i] = resource.additionalJobs[i].annualIncome;
      }
    }

    return { ...resource };
  });
};

const getValueFromNullableBool = (initialValue) => {
  if (initialValue) {
    if (initialValue === true) {
      return 'Y';
    }
    return 'N';
  }
  return null;
};

const endpointHandler = {
  endpoint: '/renewal-questionnaire',
  customResponseHandler: fixData,
  resourceType: 'renewal-questionnaire',
};

const operations = resourceOperationsGenerator(
  'renewal',
  actions,
  null,
  renewalValidator,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null,
  null
);

const {
  renewalCopyResourceToForm,
  renewalHandleFormChange,
  renewalLoadResource,
  renewalUpdateResource,
  renewalValidateAttributes,
  renewalValidateState,
} = operations;

const renewalSaveQuestionnaire = (id) => async (dispatch, getState) => {
  const state = getState();
  state.renewal.form = revertData({}, state.renewal.form);
  await dispatch(renewalUpdateResource(id));
};

const renewalEmploymentValidation = () => async (dispatch, getState) => {
  const state = getState();

  for (let i = 0; i < state.renewal.form.employersCount; i++) {
    if (!!!state.renewal.form.employers[i].employmentEmployerName) {
      await dispatch(renewalValidateAttributes(['employmentEmployerName' + i.toString()]));
    }
    if (!!!state.renewal.form.employers[i].employmentEmploymentClassification) {
      await dispatch(
        renewalValidateAttributes(['employmentEmploymentClassification' + i.toString()])
      );
    }
    if (!!!state.renewal.form.employers[i].employmentEmploymentType) {
      await dispatch(renewalValidateAttributes(['employmentEmploymentType' + i.toString()]));
    }
    if (!!!state.renewal.form.employers[i].employmentJobTitle) {
      await dispatch(renewalValidateAttributes(['employmentJobTitle' + i.toString()]));
    }
    if (!!!state.renewal.form.employers[i].employmentHours) {
      await dispatch(renewalValidateAttributes(['employmentHours' + i.toString()]));
    }
    if (!!!state.renewal.form.employers[i].employmentAddress1) {
      await dispatch(renewalValidateAttributes(['employmentAddress1' + i.toString()]));
    }
    if (!!!state.renewal.form.employers[i].employmentCity) {
      await dispatch(renewalValidateAttributes(['employmentCity' + i.toString()]));
    }
    if (!!!state.renewal.form.employers[i].employmentState) {
      await dispatch(renewalValidateAttributes(['employmentState' + i.toString()]));
    }
    if (!!!state.renewal.form.employers[i].employmentZip) {
      await dispatch(renewalValidateAttributes(['employmentZip' + i.toString()]));
    }
  }
};

const isValidDate = (date) => {
  // TODO: get actual validation requirements for this date.
  return new Date(date) > new Date('01/01/1900');
};

const revertData = (origData, data) => {
  data.clientCurrentlyMarried = data.clientCurrentlyMarried === 'Y';
  data.clientMarriageStatusCorrect = data.clientMarriageStatusCorrect === 'Y';
  data.recentOrPlannedNameChange = data.recentOrPlannedNameChange === 'Y';
  data.spouseHasStudentLoans = data.spouseHasStudentLoans === 'Y';
  data.currentlyEngaged = data.currentlyEngaged === 'Y';
  data.currentHouseholdSizeCorrect = data.currentHouseholdSizeCorrect === 'Y';
  data.returnToSchool = data.returnToSchool === 'Y';
  data.borrowMore = data.borrowMore === 'Y';
  data.currentlyEmployed = data.currentlyEmployed === 'Y';
  data.currentlyEmployedFulltime = data.currentlyEmployedFulltime === 'Y';
  data.changedJobs = data.changedJobs === 'Y';
  data.newJobNonProfit = data.newJobNonProfit === 'Y';
  data.incomeChanged = data.incomeChanged === 'Y';
  data.haveAdditionalJobs = data.haveAdditionalJobs === 'Y';
  data.furloughed = data.furloughed === 'Y';

  data.marriageDate = isValidDate(data.marriageDate) ? data.marriageDate : null;
  data.returnToSchoolDate = isValidDate(data.returnToSchoolDate) ? data.returnToSchoolDate : null;
  data.oldJobEndDate = isValidDate(data.oldJobEndDate) ? data.oldJobEndDate : null;
  data.newJobStartDate = isValidDate(data.newJobStartDate) ? data.newJobStartDate : null;

  if (data.dependents != null && data.dependents.length > 0) {
    let dependents = [];
    for (let i = 0; i < data.dependents.length; i++) {
      let dependent = {
        'dependent-name': data.dependents[i].dependentName,
        'dependent-last-name': data.dependents[i].dependentLastName,
        'dependent-relationship': data.dependents[i].dependentRelationship,
        'dependent-birthdate': data.dependents[i].dependentBirthdate,
      };
      dependents.push(dependent);
    }
    data.dependents = dependents;
  }

  if (data.additionalJobs != null && data.additionalJobs.length > 0) {
    let additionalJobs = [];
    for (let i = 0; i < data.additionalJobs.length; i++) {
      let additionalJob = {
        'income-source': data.additionalJobs[i].incomeSource,
        'annual-income': data.additionalJobs[i].annualIncome,
      };
      additionalJobs.push(additionalJob);
    }
    data.additionalJobs = additionalJobs;
  }

  if (data.employers != null && data.employers.length > 0) {
    let employers = [];
    for (let i = 0; i < data.employers.length; i++) {
      let employer = {
        'employment-employer-name': data.employers[i].employmentEmployerName,
        'employment-job-title': data.employers[i].employmentJobTitle,
        'employment-start-date': data.employers[i].employmentStartDate,
        'employment-end-date': data.employers[i].employmentEndDate,
        'employment-ein': data.employers[i].employmentEin,
        'employment-pay-periods': data.employers[i].employmentPayPeriods,
        'employment-hours': data.employers[i].employmentHours,
        'employment-address1': data.employers[i].employmentAddress1,
        'employment-address2': data.employers[i].employmentAddress2,
        'employment-city': data.employers[i].employmentCity,
        'employment-state': data.employers[i].employmentState,
        'employment-zip': data.employers[i].employmentZip,
        'employment-employment-classification':
          data.employers[i].employmentEmploymentClassification,
        'employment-employment-type': data.employers[i].employmentEmploymentType,
        'employment-employment-history-id': data.employers[i].employmentEmploymentHistoryId,
        'employment-employment-history-address-id':
          data.employers[i].employmentEmploymentHistoryAddressId,
      };
      employers.push(employer);
    }
    data.employers = employers;
  }

  return data;
};

export {
  renewalCopyResourceToForm,
  renewalHandleFormChange,
  renewalLoadResource,
  renewalSaveQuestionnaire,
  renewalValidateAttributes,
  renewalValidateState,
  renewalEmploymentValidation,
};

export default operations;
