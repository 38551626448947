import React from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Form,
  Header2,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputRadio,
  InputSelect,
  InputWrapper,
} from 'fiducius-ui';
import { Fade } from '../../../routing';

import { safeAccess } from '../../../utils';
import { idrHandleFormChange } from '../redux/operations';

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;
const InputHelp2 = styled(InputHelp)`
  color: ${(p) => p.theme.textPrimary};
`;
class TabPlan extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    handleChange: t.func,
    id: t.string.isRequired,
    validateForm: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    //this is good here because tab-plan will never be the last tab
    const attributes = ['repaymentPlan', 'inDeferOrForbearance', 'repaymentBegan'];
    this.props.validateForm(this.props.data, attributes);
  }

  render() {
    const { data = {}, errors = {}, handleChange, id } = this.props;
    return (
      <Form id={id} defaults={data} handleChange={handleChange}>
        <Header2>Tell us about your loans</Header2>
        <Fade show={data.customerStatus !== 'CRET'}>
          <InputWrapper error={!!errors.repaymentPlan}>
            <InputLabel2>
              What federal loan repayment plan are you currently enrolled in?
            </InputLabel2>
            <InputError>{safeAccess(errors, 'repaymentPlan.detail')}</InputError>
            <InputGroup affordance="medium">
              <InputSelect
                name="repaymentPlan"
                defaultValue={data.repaymentPlan}
                // TODO: ask micah about these codes
                options={{
                  '': '',
                  PAYE: 'Pay as you earn',
                  /*REPAY: 'Revised pay as you earn',*/
                  IBR: 'Income based repayment',
                  ICR: 'Income contingent repayment',
                  SAVE: 'Saving on a Valuable Education',
                  OTHER: 'Other',
                }}
              />
            </InputGroup>
          </InputWrapper>
          <InputWrapper error={!!errors.repaymentBegan}>
            <InputLabel2>When did your repayment plan begin?</InputLabel2>
            <InputError>{safeAccess(errors, 'repaymentBegan.detail')}</InputError>
            <InputGroup affordance="medium">
              <InputSelect
                name="repaymentBegan"
                defaultValue={data.repaymentBegan}
                // TODO: ask micah about these codes
                options={{
                  '': '',
                  A: 'Less than eight months ago',
                  B: 'More than eight months ago',
                }}
              />
            </InputGroup>
          </InputWrapper>
        </Fade>

        <InputWrapper error={!!errors.inDeferOrForbearance}>
          <InputLabel2>Are you currently in deferment or forbearance?</InputLabel2>
          <InputError>{safeAccess(errors, 'inDeferOrForbearance.detail')}</InputError>
          <InputRadio
            name="inDeferOrForbearance"
            value="A"
            label="No"
            defaultChecked={data.inDeferOrForbearance === 'A'}
          />
          <InputRadio
            name="inDeferOrForbearance"
            value="B"
            label="Yes, but I want to start making payments under my plan immediately."
            defaultChecked={data.inDeferOrForbearance === 'B'}
          />
          <InputRadio
            name="inDeferOrForbearance"
            value="C"
            label="Yes, and I do not want to start repaying my loans until the deferment or forbearance ends."
            defaultChecked={data.inDeferOrForbearance === 'C'}
          />
          <InputHelp2>
            This information can be found through your loan servicer account online or by contacting
            your loan servicer.
          </InputHelp2>
        </InputWrapper>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests, errors } = state.idr;
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  validateForm: (data, attributes) => dispatch(idrHandleFormChange(data, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabPlan);
