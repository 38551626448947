import React, { useMemo, useState } from 'react';
import t from 'prop-types';
import { format, startOfToday, getHours } from 'date-fns';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Datepicker,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputMask,
  InputTextarea,
  InputWrapper,
  Loader,
  Form,
} from 'fiducius-ui';

import { safeAccess, stringIsNullOrEmpty } from '../../utils';
import { Fade } from '../../routing';

const LoaderWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  padding-top: 0.5rem;
  padding-bottom: 2rem;
`;

const StyledBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
`;

const StyledText = styled(CardText)`
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 320px;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  & > button {
    margin: 0 1.5rem;
  }
`;

const StyledGroup = styled(InputGroup)`
  & .react-datepicker__time,
  & .react-datepicker__time-box,
  & .react-datepicker__time-container {
    width: 90px !important;
  }
  & .react-datepicker__navigation--next {
    right: 96px !important;
  }
  & .react-datepicker__time-list-item--disabled {
    display: none;
  }
`;

// Adjust to play nice with react-datepicker
const NoteWrapper = styled(InputWrapper)`
  align-items: flex-start;
  width: inherit;
`;

const InputHelpLeft = styled(InputHelp)`
  text-align: left;
`;

const InputLabelLeft = styled(InputLabel)`
  text-align: left;
`;

const getDatesFromSlots = (slots) => {
  const unique = [...new Set(slots.map((s) => format(s, 'MM/dd/yyyy')))];
  return unique.map((u) => new Date(u));
};

const getTimesForSelectedDate = (selected, slots) => {
  if (!selected) {
    return [];
  } else {
    return slots.filter((s) => format(s, 'MM/dd/yyyy') === format(selected, 'MM/dd/yyyy'));
  }
};

const getPhoneNumber = (phone, form) => {
  return !(form.cellPhone === undefined) ? form.cellPhone : phone.cellPhone;
};

const SchedulerForm = ({
  closeModal,
  schedule,
  slots,
  errors = {},
  form = {},
  phone = {},
  handleChange,
  request,
  rescheduleID = {},
}) => {
  const [note, setNote] = useState('');
  const [selected, setSelected] = useState(startOfToday());
  const dates = useMemo(() => getDatesFromSlots(slots), [slots]);
  const times = useMemo(() => getTimesForSelectedDate(selected, slots), [selected, slots]);

  return (
    <StyledCard brand={request.hasFailed ? 'danger' : 'primary'}>
      {request.isLoading ? (
        <StyledBody>
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </StyledBody>
      ) : (
        <>
          <StyledBody>
            <CardTitle>Schedule a meeting</CardTitle>
            <Form id="scheduleMeeting" handleChange={handleChange}>
              <StyledText>
                <InputWrapper>
                  <StyledGroup>
                    <Datepicker
                      dateFormat="MMMM d, yyyy h:mm aa"
                      onChange={setSelected}
                      includeTimes={times}
                      includeDates={dates}
                      inline
                      name="scheduler"
                      timeFormat="hh:mm aa"
                      timeCaption="Time"
                      selected={selected}
                      showTimeSelect
                    />
                  </StyledGroup>
                  <InputHelpLeft>
                    To schedule a time for a meeting:
                    <ul>
                      <li>Select a date from the calendar</li>
                      <li>Scroll to choose an available time slot</li>
                      <li>All times are shown in your local time zone</li>
                    </ul>
                  </InputHelpLeft>
                </InputWrapper>
                <InputWrapper error={!!errors.cellPhone}>
                  <InputLabelLeft htmlFor="cellPhone" required>
                    Phone Number
                  </InputLabelLeft>
                  <InputError>{safeAccess(errors, 'cellPhone.detail')}</InputError>
                  <InputGroup>
                    <InputMask
                      defaultValue={getPhoneNumber(phone, form)}
                      mask="(###) ###-####"
                      name="cellPhone"
                      placeholder="(___) ___-____"
                      type="tel"
                    />
                  </InputGroup>
                </InputWrapper>
                <NoteWrapper>
                  <InputLabel optional>Notes</InputLabel>
                  <InputGroup>
                    <InputTextarea
                      name="scheduleNote"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </InputGroup>
                  <InputHelp>Add any notes or questions for your advisor.</InputHelp>
                </NoteWrapper>
              </StyledText>
            </Form>
          </StyledBody>
          <StyledFooter>
            <Button brand="lowContrast" onClick={closeModal}>
              Cancel
            </Button>
            <Fade show={enableSubmit(form, phone, errors, selected)}>
              <Button brand="primary" onClick={() => schedule(selected, note, form, rescheduleID)}>
                Schedule
              </Button>
            </Fade>
            <Fade show={!checkNoMissingData(form, phone)}>
              Please enter the missing information above.
            </Fade>
            <Fade show={checkNoMissingData(form, phone) && !checkNoErrors(errors)}>
              Please fix the errors above.
            </Fade>
            <Fade
              show={
                !checkMeetingDateTimeIsSelected(selected) &&
                checkNoMissingData(form, phone) &&
                checkNoErrors(errors)
              }
            >
              Please select a meeting date and time.
            </Fade>
          </StyledFooter>
        </>
      )}
    </StyledCard>
  );
};

const checkNoMissingData = (form, phone) => {
  let retVal = !stringIsNullOrEmpty(getPhoneNumber(phone, form));
  return retVal;
};

const checkMeetingDateTimeIsSelected = (selected) => {
  let retVal = !(selected && getHours(selected) === getHours(startOfToday()));
  return retVal;
};

const checkNoErrors = (errors) => {
  let retVal = !errors.cellPhone || errors.cellPhone.detail === undefined;
  return retVal;
};

const enableSubmit = (form, phone, errors, selected) => {
  return (
    checkNoMissingData(form, phone) &&
    checkNoErrors(errors) &&
    checkMeetingDateTimeIsSelected(selected)
  );
};

SchedulerForm.propTypes = {
  closeModal: t.func,
  schedule: t.func.isRequired,
  slots: t.array,
  request: t.object,
};

export default SchedulerForm;
