import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardText,
  DashboardSection,
  Button,
  Header2,
  Header4,
  InputWrapper,
  ProgressBar,
} from 'fiducius-ui';
import { Redirect } from 'react-router-dom';

import { safeAccess, mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

const StyleBar = styled(ProgressBar)`
  margin-bottom: 2rem;
`;

class ReducedIDRProgress extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
    removeContainer: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '', fullServiceSteps: 9, currentURI: '' };
  }

  componentDidMount() {
    this.setState({ ...this.state, currentURI: window.location.pathname });
  }

  componentDidUpdate() {}

  goToCurrentStep = () => {
    const { permissions, todos } = this.props;
    let retVal = '/dashboard';
    if (permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null) {
      let reducedIDRStep = permissions.reducedIdrSteps.find((a) => a.stepCurrent);
      if (
        reducedIDRStep !== undefined &&
        reducedIDRStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        if (reducedIDRStep.id == 'CompleteReducedIDR') {
          retVal = '/benefits/reducedidr' + reducedIDRStep.stepPath;
        }

        const currentToDo = todos.find(
          (a) =>
            a.id === 1100 ||
            a.id === 1200 ||
            a.id === 1300 ||
            a.id === 1400 ||
            a.id === 1500 ||
            a.id === 1600 ||
            a.id === 1610 ||
            a.id === 1620 ||
            a.id === 1630
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 1100:
              retVal = '/benefits/reducedidr/welcome';
              break;
            case 1200:
              retVal = '/benefits/reducedidr/idr';
              break;
            case 1300:
              retVal = '/benefits/reducedidr/idr';
              break;
            case 1400:
              retVal = '/benefits/reducedidr/idr';
              break;
            case 1500:
              retVal = '/benefits/reducedidr/complete';
              break;
            case 1600:
              retVal = '/benefits/reducedidr/idrrenewal';
              break;
            case 1610:
              retVal = '/benefits/reducedidr/idrrenewal';
              break;
            case 1620:
              retVal = '/benefits/reducedidr/idrrenewal';
              break;
            case 1630:
              retVal = '/benefits/reducedidr/completerenewal';
              break;
          }
        }
      }
    }

    this.setState({ ...this.state, redirectURI: retVal });
  };

  getCurrentStepText = () => {
    const { permissions, todos } = this.props;
    const reducedIdrSteps = permissions.reducedIdrSteps.filter((a) => a.stepCurrent);
    let reducedIDRStep = permissions.reducedIdrSteps.find((a) => a.stepCurrent);
    if (
      reducedIdrSteps !== undefined &&
      reducedIdrSteps !== null &&
      reducedIdrSteps.length > 1 &&
      reducedIdrSteps.find((a) => window.location.pathname.includes(a.stepPath))
    ) {
      reducedIDRStep = reducedIdrSteps.find((a) => window.location.pathname.includes(a.stepPath));
    }

    let retVal = '';
    if (
      reducedIDRStep !== undefined &&
      reducedIDRStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (
        reducedIDRStep.id === 'WelcomeReducedIDR' ||
        reducedIDRStep.id === 'IDRReducedIDR' ||
        reducedIDRStep.id === 'CompleteReducedIDR'
      ) {
        retVal = reducedIDRStep.stepText;
        const currentToDo = todos.find(
          (a) =>
            (reducedIDRStep.id === 'WelcomeReducedIDR' && a.id === 1100) ||
            (reducedIDRStep.id === 'IDRReducedIDR' &&
              (a.id === 1200 ||
                a.id === 1300 ||
                a.id === 1400 ||
                a.id === 1600 ||
                a.id === 1610 ||
                a.id === 1620)) ||
            (reducedIDRStep.id === 'CompleteReducedIDR' && a.id === 1500 && a.id === 1630)
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 1100:
              retVal += '';
              break;
            case 1200:
              retVal += ' Implementation - Generate';
              break;
            case 1300:
              retVal += ' Implementation - Submit';
              break;
            case 1400:
              retVal += ' Implementation - Review';
              break;
            case 1500:
              retVal += '';
              break;
            case 1600:
              retVal += ' Renewal - Generate';
              break;
            case 1610:
              retVal += ' Renewal - Submit';
              break;
            case 1620:
              retVal += ' Renewal - Review';
              break;
            case 1630:
              retVal += '';
              break;

            default:
              break;
          }
        } else {
          if (
            reducedIDRStep.id === 'CompleteReducedIDR' &&
            reducedIDRStep.stepPath === '/completerenewal'
          ) {
            retVal = 'Renewal - ' + reducedIDRStep.stepText;
          } else if (reducedIDRStep.id === 'CompleteReducedIDR') {
            retVal = 'Implementation - ' + reducedIDRStep.stepText;
          } else {
            retVal = reducedIDRStep.stepText;
          }
        }
      } else {
        retVal = reducedIDRStep.stepText;
      }
    }

    return retVal;
  };

  getTotalSteps = () => {
    const { permissions } = this.props;
    const reducedIdrSteps = permissions.reducedIdrSteps.filter(
      (a) => a.id !== 'OverviewReducedIDR'
    );
    return reducedIdrSteps.length;
  };

  getCompletedSteps = (normalized) => {
    const { permissions } = this.props;
    const reducedIdrSteps = permissions.reducedIdrSteps.filter(
      (a) => a.id !== 'OverviewReducedIDR'
    );
    const completedSteps = reducedIdrSteps.filter((a) => a.stepComplete);
    if (normalized) {
      return 100.0 * (completedSteps.length / reducedIdrSteps.length);
    } else {
      return Math.min(completedSteps.length + 1, reducedIdrSteps.length);
    }
  };

  getCurrentStep = () => {
    const { permissions } = this.props;
    const reducedIdrSteps = permissions.reducedIdrSteps.filter(
      (a) => a.id !== 'OverviewReducedIDR'
    );
    const currentSteps = permissions.reducedIdrSteps.filter(
      (a) => a.id !== 'OverviewReducedIDR' && a.stepCurrent
    );
    const allStepsCompleted = permissions.reducedIdrSteps.filter(
      (a) => a.id === 'CompleteReducedIDR' && a.stepComplete
    );

    if (allStepsCompleted.length > 0) {
      return 0;
    } else {
      return 100.0 * (currentSteps.length / reducedIdrSteps.length);
    }
  };

  getCurrentStepPath = () => {
    const { permissions } = this.props;

    const currentSteps = permissions.reducedIdrSteps.filter(
      (a) => a.id !== 'OverviewReducedIDR' && a.stepCurrent
    );

    let stepPaths = [];
    currentSteps.forEach((step) => {
      stepPaths.push(step.stepPath);
    });
    //return an array since we can have multiple current steps.
    return stepPaths;
  };

  onCurrentStep = () => {
    let steps = this.getCurrentStepPath();
    var retVal = false;
    steps.forEach((step) => {
      if (retVal === false) {
        retVal = this.state.currentURI.includes(step);
      }
    });
    return retVal;
  };

  getLockedSteps = () => {
    return 100.0 - this.getCompletedSteps(true) - this.getCurrentStep();
  };

  render() {
    const { permissions, removeContainer } = this.props;
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      if (
        safeAccess(permissions, 'reducedIdrSteps') &&
        safeAccess(permissions, 'reducedIdrSteps').length > 1
      ) {
        return (
          <>
            {removeContainer && (
              <Card>
                <CardBody>
                  <CardText>
                    <Header2>
                      Your Reduced IDR Progress : {this.getCompletedSteps(false)} /{' '}
                      {this.getTotalSteps()}
                    </Header2>

                    <StyleBar
                      categories={[
                        {
                          brand: 'success',
                          name: 'success',
                          title: 'Completed',
                          value: this.getCompletedSteps(true),
                        },

                        {
                          brand: 'info',
                          name: 'info',
                          title: 'Current',
                          value: this.getCurrentStep(),
                        },
                        {
                          brand: 'warning',
                          name: 'warning',
                          title: 'Locked',
                          value: this.getLockedSteps(),
                        },
                      ]}
                    />
                    <div className="row col-12">
                      <div className="col-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <Header4>Current Step: {this.getCurrentStepText()}</Header4>
                      </div>
                      <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <InputWrapper>
                          {!this.onCurrentStep() && (
                            <Button brand="primary" onClick={this.goToCurrentStep}>
                              {'Go to current step'}
                            </Button>
                          )}
                        </InputWrapper>
                      </div>
                    </div>
                  </CardText>
                </CardBody>
              </Card>
            )}
            {!removeContainer && (
              <DashboardSection>
                <Card>
                  <CardBody>
                    <CardText>
                      <Header2>
                        Your Reduced IDR Progress : {this.getCompletedSteps(false)} /{' '}
                        {this.getTotalSteps()}
                      </Header2>
                      <StyleBar
                        categories={[
                          {
                            brand: 'success',
                            name: 'success',
                            title: 'Completed',
                            value: this.getCompletedSteps(true),
                          },

                          {
                            brand: 'info',
                            name: 'info',
                            title: 'Current',
                            value: this.getCurrentStep(),
                          },
                          {
                            brand: 'warning',
                            name: 'warning',
                            title: 'Locked',
                            value: this.getLockedSteps(),
                          },
                        ]}
                      />
                      <div className="row col-12">
                        <div className="col-8">
                          <Header4>Current Step: {this.getCurrentStepText()}</Header4>
                        </div>
                        <div className="col-4">
                          <InputWrapper>
                            {!this.onCurrentStep() && (
                              <Button brand="info" onClick={this.goToCurrentStep}>
                                {'Go to current step'}
                              </Button>
                            )}
                          </InputWrapper>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </DashboardSection>
            )}
          </>
        );
      } else {
        return <></>;
      }
    }
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ReducedIDRProgress));
