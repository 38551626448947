import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { withPermissions } from '../../routing';

import { authSilentRenew, isAuthenticated, shouldSilentRenew } from '../../auth';
class PrivateRoute extends React.Component {
  componentDidMount() {
    this.props.shouldRenewToken && this.props.silentRenewToken();
  }

  render() {
    const {
      component: Component,
      isAuthenticated = false,
      location,
      permissions,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuthenticated) {
            if (
              window.location.pathname === '/dashboard' ||
              window.location.pathname === '/contact' ||
              (permissions &&
                permissions.clientStatus !== 'CLOSE' &&
                permissions.clientStatus !== 'OHPAY')
            ) {
              return <Component {...rest} />;
            } else if (permissions.clientStatus === 'OHPAY' && location.pathname === '/payment') {
              return <Component {...rest} />;
            } else if (
              !(
                window.location.pathname === '/security-questions' ||
                window.location.pathname === '/security-question-check' ||
                window.location.pathname === '/password-out-of-date'
              )
            ) {
              return <Redirect to={{ pathname: '/dashboard', state: { from: location } }} />;
            } else {
              return <Component {...rest} />;
            }
          } else {
            return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
          }
        }}
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: t.oneOfType([t.string, t.func]),
  isAuthenticated: t.bool,
  location: t.any,
  shouldRenewToken: t.bool,
  silentRenewToken: t.func.isRequired,
  permissions: t.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  shouldRenewToken: shouldSilentRenew(state),
});

const mapDispatchToProps = (dispatch) => ({
  silentRenewToken: () => dispatch(authSilentRenew()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(PrivateRoute));
