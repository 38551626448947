import { FormValidator } from '../../../utils';

export class IdrValidator extends FormValidator {
  item(validator) {
    // validator.isRequired();
  }

  agi(validator, state) {
    //10m is the highest default value.
    let highestAgiValue = 10000000;
    validator.isGreaterThan(0);
    validator.validNumber();

    if (state.selfService) {
      //if IDR is greater than $3000 then we need to create an error message.
      //(AGI - POV Guidelines) * (IDR Modifier) /12 = IDR
      //(X - POVG) * (IDRM) / 12 = 3000
      // X = (((3000) * 12) / (IDRM)) + POVG
      let IDRM = 0.15;
      switch (state.existingRepaymentPlan) {
        case 'PAYE':
        case 'REPAYE':
          IDRM = 0.1;
          break;
        case 'ICR':
          IDRM = 0.2;
          break;
        default:
          IDRM = 0.15;
      }

      let POVG =
        IDRM <= 0.15
          ? state.custPovertyGuidelines.guidelineForClientIDR
          : state.custPovertyGuidelines.idrGuideline100; //state.custPovertyGuideline.guidelineForClientIDR
      highestAgiValue = (3000 * 12) / IDRM + POVG;
    }

    validator.isLessThanOrEqualTo(
      highestAgiValue,
      'If your income is greater than ' +
        highestAgiValue +
        ' please submit a contact us through your account, and we will assist you as soon as possible.'
    );
  }

  actualIdr(validator) {
    validator.isLessThanOrEqualTo(
      3000,
      'Please submit a contact us if your IDR amount is greater than $3,000.'
    );
  }

  repaymentPlan(validator, permissions) {
    if (permissions.customerStatus !== 'CRET') {
      validator.isRequired();
    }
  }

  repaymentBegan(validator, permissions) {
    if (permissions.customerStatus !== 'CRET') {
      validator.isRequired();
    }
  }

  inDeferOrForbearance(validator) {
    validator.isRequired();
  }

  question5Response(validator) {
    validator.isRequired();
  }
  question6Response(validator) {
    validator.isRequired();
  }
  question7Response(validator) {
    validator.isRequired();
  }
  question8Response(validator) {
    validator.isRequired();
  }
  question9BResponse(validator) {
    validator.isRequired();
  }
  question9CResponse(validator) {
    validator.isRequired();
  }
  question10Response(validator) {
    validator.isRequired();
  }
  question11Response(validator) {
    validator.isRequired();
  }
  question12Response(validator) {
    validator.isRequired();
  }
  question13Response(validator) {
    validator.isRequired();
  }
  question14Response(validator) {
    validator.isRequired();
  }
  question15Response(validator) {
    validator.isRequired();
  }
  question16Response(validator) {
    validator.isRequired();
  }
  question17Response(validator) {
    validator.isRequired();
  }
  question18Response(validator) {
    validator.isRequired();
  }
  question19Response(validator) {
    validator.isRequired();
  }
  question20Response(validator) {
    validator.isRequired();
  }
}

export default IdrValidator;
