import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Header2,
  Link,
} from 'fiducius-ui';

import { getUserId, authLoadPermissions } from '../../../auth';
import { mergeRequestStatuses, safeAccess } from '../../../utils';

import { renewalSaveQuestionnaire } from '../redux/operations';
import { renewalResultsLoadResource } from '../../../renewal-results';
import { todosLoadCollection } from '../../../todos/redux/operations';

class TabResults extends React.Component {
  static propTypes = {
    data: t.object,
    getInfo: t.func.isRequired,
    id: t.string.isRequired,
    request: t.object,
    saveRenewal: t.func.isRequired,
    saveRequest: t.object,
    formsAreValid: t.bool.isRequired,
  };

  componentDidMount() {
    const { id, saveRenewal, formsAreValid } = this.props;
    if (formsAreValid) saveRenewal(id);
  }

  componentDidUpdate(prevProps) {
    const { getInfo, id, saveRequest } = this.props;
    if (prevProps.saveRequest.isLoading && saveRequest.hasFinished && !saveRequest.hasFailed) {
      getInfo(id);
    }
  }

  render() {
    const { data = {}, request } = this.props;
    return (
      <AsyncLoader request={request}>
        <Header2>Your renewal is almost done. What&#39;s next?</Header2>
        <div className="row">
          <div className="col-12 col-md-12">
            {!data.selfService && (
              <Card brand={data.documentsReceived ? 'success' : 'primary'}>
                <CardBody>
                  <CardTitle>
                    Congratulations, you are one step closer to improving your financial wellness!
                  </CardTitle>
                  <CardText>
                    <p>
                      As a result of the COVID-19 pandemic and the CARES act stimulus package, there
                      are several temporary changes to the Public Service Loan Forgiveness (PSLF)
                      program. Importantly, these changes impact the potential financial benefit for
                      nearly all PSLF participants. However, the new rules are confusing, making it
                      difficult to understand and navigate your best path to forgiveness.
                    </p>
                    <p>
                      Don't worry! Fiducius has done all the heavy lifting so it's easy for you to
                      make the right choices to improve your financial wellness. All you need to do
                      is follow our step-by-step guidance provided in your "To-Do" steps. Our
                      process ensures accuracy in submitting your renewal.
                    </p>
                    <p>
                      Should you have questions as you go through the process, please use the
                      "Contact Us" button in the left navigation pane of your portal to submit your
                      request for help.
                    </p>
                  </CardText>
                </CardBody>
                {!data.documentsReceived && (
                  <CardFooter>
                    <Link to="/documents">Go to my documents</Link>
                  </CardFooter>
                )}
              </Card>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            {data.selfService && (
              <Card brand="success">
                <CardBody>
                  <CardTitle>
                    Congratulations, you are one step closer to improving your financial wellness!
                  </CardTitle>
                  <CardText>
                    <p>
                      As a result of the COVID-19 pandemic and the CARES act stimulus package, there
                      are several temporary changes to the Public Service Loan Forgiveness (PSLF)
                      program. Importantly, these changes impact the potential financial benefit for
                      nearly all PSLF participants. However, the new rules are confusing, making it
                      difficult to understand and navigate your best path to forgiveness.
                    </p>
                    <p>
                      Don't worry! Fiducius has done all the heavy lifting so it's easy for you to
                      make the right choices to improve your financial wellness. All you need to do
                      is follow our step-by-step guidance provided in your "To-Do" steps. Our
                      process ensures accuracy in submitting your renewal.
                    </p>
                    <p>
                      Should you have questions as you go through the process, please use the
                      "Contact Us" button in the left navigation pane of your portal to submit your
                      request for help.
                    </p>
                  </CardText>
                </CardBody>
                <CardFooter>
                  <Link to="/dashboard">Return to dashboard</Link>
                </CardFooter>
              </Card>
            )}
          </div>
        </div>
      </AsyncLoader>
    );
  }
}

const mapStateToProps = (state) => {
  const { renewal } = state;

  return {
    id: getUserId(state),
    data: safeAccess(state, `renewalResults.cache[${getUserId(state)}]`) || {},

    request: mergeRequestStatuses([
      state.renewal.requests.updateResource,
      state.renewalResults.requests.loadResource,
    ]),
    saveRequest: state.renewal.requests.updateResource,
    formsAreValid: renewal.meta.isValid,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInfo: (id) => dispatch(renewalResultsLoadResource(id)),
  saveRenewal: async (id) => {
    await dispatch(renewalSaveQuestionnaire(id));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TabResults);
