import React, { useState } from 'react';
import { DashboardSection, Header2 } from 'fiducius-ui';

import { AffiliateTile } from '../../benefits/affiliates';
import { IdentityEnrolled } from '../../benefits/identity';
import { InsuranceQualified } from '../../benefits/insurance';
import { RefinanceSummary } from '../../benefits/refinance';
import { ForgivenessSummary } from '../../benefits/forgiveness';
import { SimpliFiSummary } from '../../benefits/simplifi';
import { ContributionSummary } from '../../benefits/contribution';
import { TuitionSummary } from '../../benefits/tuition';
import { LwoSummary } from '../../benefits/limited-waiver-opportunity';
import { EmpCertSummary } from '../../benefits/empcert';
import { OptimalSituationSummary } from '../../benefits/optimal-situation';
import { ReducedIDRSummary } from '../../benefits/reducedidr';
import { FreshStartSummary } from '../../benefits/freshstart';
import { EducationalReengagementSummary } from '../../benefits/educational-reengagement';
import { PayNow } from '../../payment';
import { withPermissions } from '../../routing';
import { OutstandingTodos } from '../../todos';
import { Closed } from '../../closed';
import { MessageTile } from '../../messages';

import { SharedDataConsent } from '../../legal';
import DashboardBanner from '../components/dashboard-banner';
import QualifiedTuition from '../components/qualified-tuition';
import CurrentEventsBanner from '../components/current-events-banner';
import SubscriptionHighlightModal from '../components/subscription-highlight-modal';

const ShowSharedDataConsent = (permissions, isEmulation) => {
  let retVal = false;
  if (
    isEmulation !== true &&
    permissions.hasConsentToShareData &&
    (permissions.consentFormCode === 'ASCRE' || permissions.consentFormCode === 'AREGI')
  ) {
    retVal = true;
  }
  return retVal;
};

const MyDashboard = withPermissions(({ theme, permissions, toggleDrawer, isEmulation }) => {
  if (permissions.clientStatus !== 'CLOSE' && permissions.clientStatus !== 'OHPAY') {
    const [isOpen, setOpen] = useState(true);
    const toggleModal = () => {
      setOpen(!isOpen);
    };
    return (
      <>
        <DashboardSection title="My Dashboard">
          <Header2>All your financial wellness at a glance.</Header2>
          {(permissions.showForgiveness || permissions.showSimplifi) && <CurrentEventsBanner />}
          {permissions.qualifiedTuition && <QualifiedTuition />}
          {permissions.hasAnyTodos && <OutstandingTodos toggleDrawer={toggleDrawer} />}
          {ShowSharedDataConsent(permissions, isEmulation) && (
            <SharedDataConsent hideIcon={true} isOpen={isOpen} toggleModal={toggleModal} />
          )}
          {permissions.subscriptionAvailable && !isEmulation && <SubscriptionHighlightModal />}
          <DashboardBanner />
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8">
              {permissions.showForgiveness && <ForgivenessSummary />}
              {permissions.hasEnterpriseEmpCert && <EmpCertSummary />}
              {permissions.showLwo && <LwoSummary />}
              {permissions.hasOptimalSituation && <OptimalSituationSummary />}
              {permissions.hasReducedIDR && <ReducedIDRSummary />}
              {permissions.hasFreshStart && <FreshStartSummary />}
              {permissions.hasEducationalReengagement && <EducationalReengagementSummary />}
              {(permissions.showTuition || permissions.tuitionReadOnly) && <TuitionSummary />}
              {(permissions.showContribution || permissions.contributionReadOnly) && (
                <ContributionSummary fromDashboard={true} />
              )}
              {permissions.showSimplifi && <SimpliFiSummary />}
              {permissions.showRefinance && <RefinanceSummary />}
            </div>
            <div className="col-12 col-lg-5 col-xl-4">
              {permissions.showIdentity && <IdentityEnrolled />}
              {permissions.showStudentLoanDisabilityInsurance && <InsuranceQualified />}
              <AffiliateTile theme={theme} />
              <MessageTile tileTitle={''} messageType={'Forgiveness_Dashboard'} />
              {permissions.showPayNow && <PayNow />}
              {permissions.showRenewalPayNow && <PayNow payNowType={'renewal'} />}
            </div>
          </div>
        </DashboardSection>
      </>
    );
  } else {
    return (
      <>
        <Closed />
      </>
    );
  }
});

export default MyDashboard;
