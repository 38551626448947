// Generic validator functions
import { JsonApiError } from './error';
import { getDate, lastDayOfMonth, format } from 'date-fns';

// We don't use \w\d\s becuase they can be implemented differently in non-PCRE implementations
// [\w] = [a-zA-Z0-9_] - Notice the underscore that we don't want either
// [\d] = [0-9]
// [\s] = [\r\n\t\f\v ] - return, newline, tab, form-feed, vertical whitespace, space literal
export const emailPattern = /^([a-zA-Z\d!#$%&'*+\-/=?^_`{}|]+[_+.-])*[a-zA-Z\d!#$%&'*+\-/=?^_`{}|']+@(\w+[.-])*\w{1,63}\.[a-zA-Z]+$/g;
const phonePattern = /(\([0-9]{3}\) [0-9]{3}-[0-9]{4}|[0-9]{10})/g;
const passwordPattern = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9\r\n\t\f\v ])([^\r\n\t\f\v ]){8,50}$/g;
const multipleEmailWithoutSemicolon = /^.+[^;][\s]\S+$/g;

export class AttributeValidator {
  constructor(attribute, value) {
    this.attribute = attribute;
    this.value = value;
    this.error = new ValidationError();
  }

  hasFailed = () => {
    return this.error.hasFailures();
  };

  throwValidationError = () => {
    throw this.error;
  };

  handleFailure = (message) => {
    const failure = new ValidationFailure(`This field ${message}`, this.attribute);
    this.error.addFailure(failure);
  };

  isGreaterThanOrEqualTo = (limit) => {
    if (this.value < limit) {
      this.handleFailure(`must be greater than or equal to ${limit}.`);
    }
  };

  isGreaterThan = (limit) => {
    if (this.value <= limit) {
      this.handleFailure(`must be greater than ${limit}.`);
    }
  };

  isLessThan = (limit) => {
    if (this.value >= limit) {
      this.handleFailure(`must be less than ${limit}.`);
    }
  };

  isLessThanOrEqualTo = (limit, customMessage) => {
    if (this.value > limit) {
      if (!customMessage) {
        this.handleFailure(`must be less than or equal to ${limit}.`);
      } else {
        this.handleFailure(`must be less than or equal to ${limit}. ` + customMessage);
      }
    }
  };

  isRequired = () => {
    if (!this.value && this.value !== false && this.value !== 0) {
      this.handleFailure('is required.');
    }
  };

  addCustomMessage = (message) => {
    if (message.length > 0) {
      this.handleFailure(message);
    }
  };

  isValidSingleEmail = () => {
    this.matchesRegex(emailPattern, 'must be a valid email address');
  };

  isValidEmail = () => {
    if (this.value.includes(';') || this.value.match(multipleEmailWithoutSemicolon)) {
      let failure = false;
      let array = this.value.split(';');
      array.forEach((value) => {
        if ((value !== null && !value.trim().match(emailPattern)) || array.length === 1) {
          failure = true;
        }
      });

      if (failure) {
        this.handleFailure('must have valid email addresses separated by a semicolon');
      }
    } else {
      this.matchesRegex(emailPattern, 'must be a valid email address');
    }
  };

  isValidPassword = () => {
    this.matchesRegex(
      passwordPattern,
      'must be between 8 and 50 characters using at least one of each: <ul><li>Uppercase letters</li><li>Lowercase letters</li><li>Numbers</li><li>Special characters ` ~ ! @ # $ % ^ & * ( ) - _ = + [ { ] } \\ | ; : \' " , < . > / ?</li></ul>'
    );
  };

  isValidPhoneNumber = () => {
    this.matchesRegex(phonePattern, 'must be formatted as (###) ###-####');
  };

  isValidSecurityQuestion = () => {
    if (this.value === '0') {
      this.handleFailure(`Please select a security question`);
    }
  };

  valueMatchesList = (value, list) => {
    for (let item of list) {
      if (item.toUpperCase() === value.toUpperCase()) {
        this.handleFailure('must be a different value.');
      }
    }
  };

  hasMaxLength = (maxLength) => {
    if (this.value != null && this.value.length > maxLength) {
      this.handleFailure(`must be ${maxLength} characters or less.`);
    }
  };

  hasMinLength = (minLength) => {
    if (this.value != null && this.value.length < minLength) {
      this.handleFailure(`must be ${minLength} characters or more.`);
    }
  };

  validNumber = () => {
    if (this.value === undefined || this.value === '' || isNaN(this.value)) {
      this.handleFailure('is an invalid number.');
    }
  };

  checkRequired = () => {
    if (this.value === false) {
      this.handleFailure('is required to be checked to continue.');
    }
  };

  noBlankSpace = () => {
    if (this.value.trim().length === 0) {
      this.handleFailure('cannot contain just blank spaces.');
    }
  };

  requiredSelection = () => {
    if (this.value === undefined || this.value === null || this.value === '') {
      this.handleFailure('is required.');
    }
  };

  isAfterDate = (secondDate) => {
    const compareDate = new Date(secondDate);
    const enteredDate = new Date(this.value);

    if (enteredDate < compareDate) {
      this.handleFailure(`date must be later than ${format(compareDate, 'MM/dd/yyyy')}`);
    }
  };

  isBeforeDate = (secondDate) => {
    const compareDate = new Date(secondDate);
    const enteredDate = new Date(this.value);

    if (enteredDate > compareDate) {
      this.handleFailure(`date must be before ${format(compareDate, 'MM/dd/yyyy')}`);
    }
  };

  isOnOrAfterDate = (secondDate) => {
    const compareDate = new Date(secondDate);
    const enteredDate = new Date(this.value);

    if (enteredDate < compareDate - 1) {
      this.handleFailure(`date must be on or later than ${format(compareDate, 'MM/dd/yyyy')}`);
    }
  };

  isOnOrBeforeDate = (secondDate) => {
    const compareDate = new Date(secondDate);
    const enteredDate = new Date(this.value);

    if (enteredDate > compareDate) {
      this.handleFailure(`date must be on or before ${format(compareDate, 'MM/dd/yyyy')}`);
    }
  };

  validDate = () => {
    if (this.value.includes('/')) {
      let dateArray = this.value.split('/');
      let lastDay = getDate(lastDayOfMonth(new Date(dateArray[2], dateArray[0], 0)));

      if (
        this.value === undefined ||
        this.value === '' ||
        new Date(this.value) === 'Invalid date' ||
        !(dateArray[1] <= lastDay) //dateArray[1] holds the Date the client entered.
      ) {
        this.handleFailure('is an invalid date.');
      }
    }
  };

  matchesRegex = (regex, message) => {
    if (this.value !== null && !this.value.match(regex)) {
      this.handleFailure(message);
    }
  };
}

export class FormValidator {
  constructor() {
    this.error = new ValidationError();
  }

  clearState = () => {
    this.error = new ValidationError();
  };

  validateState = (state) => {
    this.clearState();
    const methods = Object.getOwnPropertyNames(this.constructor.prototype);

    methods.forEach((method) => {
      if (
        method !== 'clearState' &&
        method !== 'constructor' &&
        method !== 'finalizeValidator' &&
        method !== 'tryValidate' &&
        method !== 'validateAttribute' &&
        method !== 'validateState' &&
        method !== 'validate'
      ) {
        this.tryValidate(method, state[method], state);
      }
    });

    this.finalizeValidator();
  };

  validateAttributes = (attributes, state) => {
    this.clearState();

    attributes.forEach((attribute) => {
      this.tryValidate(attribute, state[attribute], state);
    });
    this.finalizeValidator();
  };

  tryValidate = (attribute, value, state) => {
    try {
      this.validate(attribute, value, state);
    } catch (e) {
      if (e instanceof ValidationError) {
        e.getFailures().forEach((failure) => {
          this.error.addFailure(failure);
        });
      } else {
        throw e;
      }
    }
  };

  validate = (attribute, value, state) => {
    const validator = new AttributeValidator(attribute, value);
    if (this[attribute]) {
      this[attribute](validator, state);
      if (validator.hasFailed()) {
        validator.throwValidationError();
      }
    }
  };

  finalizeValidator = () => {
    if (this.error.hasFailures()) {
      throw this.error;
    }
  };
}

export class ValidationError extends JsonApiError {
  constructor() {
    const errors = [];
    const mockResponse = {
      status: 422,
      statusText: 'Client Side Validation Error',
    };

    super(errors, mockResponse);
    this.name = 'ValidationError';
    this.errors = [];
  }

  addFailure = (failure) => {
    this.errors.push({ ...failure });
  };

  hasFailures = () => {
    return this.errors.length > 0;
  };

  getFailures = () => {
    return this.errors;
  };
}

class ValidationFailure {
  constructor(message, attribute) {
    this.title = 'Client Side Validation Failure';
    this.detail = message;
    this.status = 422;
    this.source = {
      pointer: '/data/attributes/' + attribute,
    };
  }
}
