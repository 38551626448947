import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, DashboardSection } from 'fiducius-ui';

import { mergeRequestStatuses, convertIsoToSlash } from '../../../utils';

import { withPermissions } from '../../../routing';

import EmpCertProgress from '../components/emp-cert-progress';

class EmpCertCorrection extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  getNextReviewDate() {
    const { permissions } = this.props;
    const empCertStep = permissions.enterpriseEmpcertSteps.find(
      (a) => a.id === 'CompleteEnterpriseEmpcert'
    );

    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        return convertIsoToSlash(empCertStep.stepNextReviewDate);
      } else {
        return null;
      }
    }
  }

  componentDidUpdate() {}

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { request, permissions } = this.props;

    return (
      <>
        <AsyncLoader request={request}>
          <div id="step3Section">
            <EmpCertProgress />
            <DashboardSection title="Employment Information Correction/Update">
              <Card>
                <CardBody>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <ul>
                      <li>
                        You confirmed that information on your employment certification form was
                        incorrect. It is your responsibility to contact your employer to discuss the
                        information you feel is incorrect, if you have not done so yet.
                      </li>
                      <li>
                        When your employer corrects the incorrect information, you will receive an
                        email notification that you can generate a new employment certification
                        form.
                      </li>
                      <li>
                        If the previously provided employment information is accurate, please notify
                        us via Contact Us.
                      </li>
                    </ul>
                  </AsyncLoader>
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(EmpCertCorrection));
