import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Button,
  Lede,
} from 'fiducius-ui';

import { mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

import RenewalConfirmation from '../components/renewal-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';
import StepDisplay from '../components/step-display';
import { EmployerApprovers, employmentHistoryLoadCollection } from '../../../employment-history';
import { MessageTile } from '../../../messages';
import { MessageDiv } from '../../../messages';

class EmpCertSS extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
  };

  constructor(props) {
    super(props);
    this.state = { showStep1: false, showStep1b: false, showStep2: false, showStep3: false };
  }

  componentDidMount() {
    this.props.load();

    const pathName = window.location.pathname;
    if (pathName.includes('/step')) {
      this.setState({
        ...this.state,
        showStep1: pathName.includes('/step-1'),
        showStep2: pathName.includes('/step-2'),
        showStep3: pathName.includes('/step-3'),
      });
    }
  }

  componentDidUpdate() {}

  handleStep1Click = () => {
    this.handleSectionClick('step1');
  };
  handleStep2Click = () => {
    this.handleSectionClick('step2');
  };
  handleStep3Click = () => {
    this.handleSectionClick('step3');
  };

  handleSectionClick = (sectionName) => {
    if (sectionName === 'step1') {
      this.setState(
        {
          showStep1: true,
          showStep2: false,
          showStep3: false,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    } else if (sectionName === 'step2') {
      this.setState(
        {
          showStep1: false,
          showStep2: true,
          showStep3: false,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    } else if (sectionName === 'step3') {
      this.setState(
        {
          showStep1: false,
          showStep2: false,
          showStep3: true,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    }
  };

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  getStep1Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'EmpCert');
    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        retVal = 'success';
      } else {
        if (todos !== undefined && todos !== null) {
          const currentToDo = todos.find((a) => a.id === 252);
          if (currentToDo !== undefined && currentToDo !== null) {
            retVal = 'info';
          } else {
            const nextToDo = todos.find((a) => a.id === 253 || a.id === 254);
            if (nextToDo !== undefined && nextToDo !== null) {
              retVal = 'success';
            }
          }
        }
      }
    }

    return retVal;
  };

  getStep2Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'EmpCert');
    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        retVal = 'success';
      } else {
        if (todos !== undefined && todos !== null) {
          const currentToDo = todos.find((a) => a.id === 253);
          if (currentToDo !== undefined && currentToDo !== null) {
            retVal = 'info';
          } else {
            const nextToDo = todos.find((a) => a.id === 254);
            if (nextToDo !== undefined && nextToDo !== null) {
              retVal = 'success';
            }
          }
        }
      }
    }

    return retVal;
  };

  getStep3Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'EmpCert');
    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        retVal = 'success';
      } else {
        if (todos !== undefined && todos !== null) {
          const currentToDo = todos.find((a) => a.id === 254);
          if (currentToDo !== undefined && currentToDo !== null) {
            retVal = 'info';
          }
        }
      }
    }

    return retVal;
  };

  getStep3RestrictionDate = () => {
    let retVal = undefined;
    const { permissions } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'EmpCert');
    if (empCertStep.stepDateRestriction !== undefined) {
      let stepRestriction = new Date(empCertStep.stepDateRestriction);
      retVal =
        (stepRestriction.getMonth() + 1).toString() +
        '/' +
        stepRestriction.getDate() +
        '/' +
        stepRestriction.getFullYear();
    }
    return retVal;
  };

  render() {
    const { request, permissions } = this.props;
    const restrictionDate = this.getStep3RestrictionDate();
    const electronicEmpCertApprovalEnabled = permissions.electronicEmpcertEnabled;
    const step1Complete = this.getStep1Brand() === 'success';

    return (
      <>
        <AsyncLoader request={request}>
          <ForgivenessProgress />
          <DashboardSection>
            <Card brand="primary">
              <CardBody>
                <CardTitle>Employment Certification</CardTitle>
                <CardText>
                  <p>
                    Completing this step will certify your employment and previous loan payments.
                  </p>
                  <p>
                    <MessageDiv messageType={'EmpCert_Tab'} />
                  </p>
                  <div className="row col-12">
                    <div className="col-4">
                      <Lede>
                        <Button brand={this.getStep1Brand()} onClick={this.handleStep1Click}>
                          Generate
                        </Button>
                      </Lede>
                    </div>
                    <div className="col-4">
                      <Lede>
                        <Button brand={this.getStep2Brand()} onClick={this.handleStep2Click}>
                          Submit
                        </Button>
                      </Lede>
                    </div>
                    <div className="col-4">
                      <Lede>
                        <Button brand={this.getStep3Brand()} onClick={this.handleStep3Click}>
                          Review
                        </Button>
                      </Lede>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </DashboardSection>
        </AsyncLoader>
        {this.state.showStep1 && !electronicEmpCertApprovalEnabled && (
          <div id="step1Section">
            <DashboardSection title="Generate your Employment Certification">
              <Card>
                <CardBody>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay
                      title={'Step 1 - Generate and Download Employment Certification form'}
                    >
                      <ul>
                        <li>
                          Please click the button below to generate your Employment Certification
                          Form.
                        </li>
                        <li>
                          After clicking the button, it will take you to your My Documents page,
                          where you can download the file.
                        </li>
                        <li>
                          Once you've downloaded the document, you can continue following the
                          instructions on this page.
                        </li>
                      </ul>
                    </StepDisplay>
                  </AsyncLoader>
                  <RenewalConfirmation stepId={252} />
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}

        {this.state.showStep1 && electronicEmpCertApprovalEnabled && (
          <div id="step1Section">
            <DashboardSection title="Generate your Employment Certification">
              <div id="messageSection">
                <MessageTile messageType={'EmpCert_Generate'} />
              </div>
              <Card>
                <CardBody>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 - Provide Your Authorized Official(s)'}>
                      <ul>
                        <li>
                          Please provide the email of the authorized official for each employer
                          listed below. If you do not know the email, please select "I do not know
                          my authorized official's email."
                        </li>
                      </ul>

                      {(this.state.showStep1b || step1Complete) && (
                        <p>You've completed this step.</p>
                      )}

                      {!this.state.showStep1b && !step1Complete && (
                        <EmployerApprovers
                          stepState={() => this.setState({ ...this.state, showStep1b: true })}
                          checkQualification={() => {}}
                        />
                      )}
                    </StepDisplay>
                  </AsyncLoader>
                  {(this.state.showStep1b || step1Complete) && (
                    <>
                      <AsyncLoader request={this.props.request} loading={<></>}>
                        <StepDisplay
                          title={'Step 2 – Generate and Complete Employment Certification form'}
                        >
                          <ul>
                            <li>
                              Please click the button below to generate your Employment
                              Certification form(s).
                            </li>
                            <li>
                              After clicking the button, you will be directed to sign the first page
                              of the form and an email will be sent to the authorized official(s)
                              you provided to complete the second page of the form.
                            </li>
                            <li>
                              If you selected "I do not know my authorized official's email," you
                              will be required to download the file from your{' '}
                              <strong>My Documents</strong> page.
                              <ul>
                                <li>
                                  The first page will need to be completed with a hand-written
                                  signature at the bottom.
                                </li>
                                <li>
                                  You will need to provide the second page of the form to your
                                  employer for completion. Please check with your employer to
                                  determine who is authorized to complete the form.
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </StepDisplay>
                      </AsyncLoader>
                      <RenewalConfirmation stepId={252} />
                    </>
                  )}
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep2 && !electronicEmpCertApprovalEnabled && (
          <div id="step2Section">
            <DashboardSection title="Submit your Employment Certification">
              <Card>
                <CardBody>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 – Complete and send Employment Certification form'}>
                      <ul>
                        <li>
                          Print the Employment Certification form in the My Documents section of
                          your MyFiducius portal and <strong>hand sign</strong> page 1.
                        </li>
                        <li>
                          Your employer must complete and <strong>hand sign</strong> the second
                          page.
                        </li>
                        <li>
                          <strong>Please note:</strong> The Department of Education does not accept
                          electronic signatures on this document.
                        </li>
                        <li>
                          Please verify your Social Security Number is present on the first two
                          pages of your employment certification(s) before submitting.
                        </li>
                        <li>
                          Questions 1 through 9 <strong>must</strong> be completed. Please read the
                          answer to question 9 to determine if the following questions need a
                          response.
                        </li>
                      </ul>
                    </StepDisplay>
                    <StepDisplay title={'Step 2 – Upload the form(s) to your Fiducius account'}>
                      <ul>
                        <li>
                          Upload the form under <strong>My Documents</strong>.
                        </li>
                      </ul>
                    </StepDisplay>
                  </AsyncLoader>
                  <RenewalConfirmation stepId={253} />
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep2 && electronicEmpCertApprovalEnabled && (
          <div id="step2Section">
            <DashboardSection title="Submit your Employment Certification">
              <div id="messageSection">
                <MessageTile messageType={'EmpCert_Submit'} />
              </div>
              <Card>
                <CardBody>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 – Complete and send Employment Certification form'}>
                      <ul>
                        <li>
                          If you provided an authorized official's email, you will be required to
                          complete the first page of the form electronically by selecting your
                          employer(s) below.
                          <ul>
                            <li>
                              The second page of the form will be sent to your employer for
                              completion.
                            </li>
                          </ul>
                        </li>
                        <li>
                          If you selected "I do not know my authorized official's email," you will
                          be required to download the file from your <strong>My Documents</strong>{' '}
                          page and complete it in its entirety.
                        </li>
                        <ul>
                          <li>
                            Please verify your Social Security Number is present on the first two
                            pages of your employment certification(s) before submitting.
                          </li>
                          <li>
                            The first page will need to be completed with a hand-written signature
                            at the bottom.
                          </li>
                          <li>
                            You will need to provide the second page of the form to your employer
                            for completion. Please check with your employer to determine who is
                            authorized to complete the form.
                          </li>
                          <li>
                            Completed form(s) must be uploaded under your{' '}
                            <strong>My Documents</strong> page.
                          </li>
                        </ul>
                      </ul>
                    </StepDisplay>
                  </AsyncLoader>
                  <RenewalConfirmation stepId={253} />
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep3 && (
          <div id="step3Section">
            <DashboardSection title="Check on your Employment Certification">
              <div id="messageSection">
                <MessageTile messageType={'EmpCert_Review'} />
              </div>
              <Card
                overlayCard={restrictionDate !== undefined && !permissions.isEmpcertCheckPaused}
              >
                {!permissions.isEmpcertCheckPaused && (
                  <CardBody overlayCard hideOverlay={restrictionDate === undefined}>
                    {restrictionDate !== undefined && (
                      <>
                        <CardTitle overlayCard>Wait for PSLF Application Results letter</CardTitle>
                        <CardText overlayCard>
                          <p>
                            Based on the average processing time for this document, it will be
                            processed by your loan servicer on {restrictionDate} at the earliest*.
                            You will not be able to confirm completion of this step until that date.
                          </p>
                          <p>
                            *Please note: Your document is not guaranteed to be processed by this
                            date. It is important to follow your steps closely.
                          </p>
                        </CardText>
                      </>
                    )}
                  </CardBody>
                )}
                {permissions.isEmpcertCheckPaused && (
                  <>
                    <CardBody overlayCard>
                      <>
                        <CardTitle overlayCard>Application Pause</CardTitle>
                        <CardText overlayCard>
                          <p>
                            This step can not be completed at this time. Beginning{' '}
                            <b>May 1, 2024</b>, the Department of Education changed how it processes
                            the Public Service Loan Forgiveness Program documentation. As a result,
                            the processing of Employment Certification documentation is paused{' '}
                            <b>until July 2024</b>. Please return to your MyFiducius portal and
                            complete it once Fiducius notifies you that the Department of Education
                            has resumed processing, projected for <b>July 2024</b>.
                          </p>
                        </CardText>
                      </>
                    </CardBody>
                  </>
                )}
                <CardBody
                  disableChildren={
                    restrictionDate !== undefined || permissions.isEmpcertCheckPaused
                  }
                >
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 – Wait for PSLF Application Results from MOHELA.'}>
                      <ul>
                        <li>The approval letter states your form has been accepted.</li>
                        <li>
                          There may also be an additional update letter which provides your number
                          of qualifying payments towards the Public Service Loan Forgiveness program
                          up to the date your employer signed your Employment Certification form.
                        </li>
                        <li>
                          These typically arrive <strong>one to two months</strong> after the form
                          is submitted.{' '}
                        </li>
                      </ul>
                      <strong>
                        Do not confirm this step is finished until you have received both the PSLF
                        Application Results and Qualifying Payment update letters.
                      </strong>
                    </StepDisplay>
                  </AsyncLoader>
                  {!permissions.isEmpcertCheckPaused && <RenewalConfirmation stepId={254} />}
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({
  load: async () => {
    dispatch(employmentHistoryLoadCollection());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(EmpCertSS));
