import React from 'react';
import { AnimationGroup } from 'fiducius-ui';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PrivateRoute, withPermissions } from '../../routing';

import { MyAccounts } from '../../accounts';
import { MyDashboard } from '../../dashboard';
import { MyDocuments } from '../../documents';
// import { MyFuture } from '../../future';
// import { MyNotifications } from '../../notifications';
import { PlanTool } from '../../tools/plan-tool';
import { MyProfile } from '../../profile';
import { MyPasswordAuth } from '../../password-reset-auth';
import { MySettings } from '../../settings';
import { Closed } from '../../closed';
import { Faq } from '../../faq';
// import { MyTodoList } from '../../todolist';

import {
  InactiveContributions,
  ActiveContributions,
  ContributionOverview,
} from '../../benefits/contribution';

import {
  MyForgiveness,
  ForbearancePhaseOne,
  ForbearancePhaseOneFS,
  ForbearancePhaseTwo,
  EmpCert,
  EmpCertFS,
  Consolidation,
  ConsolidationFS,
  ContributionRSSS,
  ContributionSS,
  Idr,
  IDRFS,
  OHGraduation,
  Welcome,
  Taxes,
  Complete,
  ProfileVerification,
  RenewalQuestionnaire,
  RenewalExtension,
  FeeRS,
  ForbearanceRS,
  EmpCertSS,
  IDRSS,
  DocNeededRSFS,
  MeetingRSFS,
  IDRRSFS,
  AccountReviewRS,
  Recertification,
  EmpCertRSFS,
  BtsReview,
  BtsQuestionnaire,
  NSLDSUpload,
  IdrReduced,
} from '../../benefits/forgiveness';

//import { MyBenefitModeler } from '../../tools/modeler';
import { MyPretaxTool } from '../../tools/pretax-tool';
import { MyRetirementTool } from '../../tools/retirement-tool';
import { TaxEstimatorTool } from '../../tools/tax-estimator-tool';
import { MyTaxTool } from '../../tools/taxtool';
import { MyInterestComparison } from '../../tools/interestcomparison';
import { MySLAComparison } from '../../tools/sla-comparison';
import { MyIdentity } from '../../benefits/identity';
import { MyInsurance } from '../../benefits/insurance';
import { MyRefinance } from '../../benefits/refinance';
import { MyRetirement } from '../../benefits/retirement';
import { Contact } from '../../contact';
import { MySimpliFi } from '../../benefits/simplifi';
import {
  MyTuition,
  MyInstitutions,
  MyTerms,
  MyClasses,
  MyReimbursementHistory,
} from '../../benefits/tuition';
import {
  ConsolidationLwo,
  LwoAgreement,
  LwoLoanInfo,
  LwoOverview,
  LwoQuestionnaire,
  LwoFee,
  LwoEmploymentCertification,
  LwoComplete,
  LwoNotQualified,
  LwoFinalConfirmation,
  AboutAssure,
} from '../../benefits/limited-waiver-opportunity';
import { SecurityQuestionsDashboard } from '../../security-questions-setup';
import {
  EmpCertGen,
  EmpCertSubmit,
  EmpCertCheck,
  EmpCertComplete,
  EmpCertCorrection,
} from '../../benefits/empcert';
import {
  FreshStartAwaiting,
  FreshStartComplete,
  FreshStartContact,
  FreshStartOverview,
  FreshStartNSLDS,
} from '../../benefits/freshstart';
import { EducationalReengagementOverview } from '../../benefits/educational-reengagement';
import {
  ReducedIDRWelcome,
  ReducedIDROverview,
  ReducedIDRComplete,
} from '../../benefits/reducedidr';
import { PlaidOverview } from '../../plaid';
import { RepaymentOverview } from '../../loan-repayment';

const IdrReducedRenewal = () => {
  return <IdrReduced renewal={true} />;
};

const IdrReducedRenewalComplete = () => {
  return <ReducedIDRComplete renewal={true} />;
};

const RouterDashboard = withPermissions(({ theme, permissions, toggleDrawer, isEmulation }) => (
  <Route
    render={({ location }) => (
      <AnimationGroup key={location.key} animation="fade">
        <Switch location={location}>
          {/* Home sections */}
          <PrivateRoute
            exact
            path="/dashboard"
            component={MyDashboard}
            toggleDrawer={toggleDrawer}
            theme={theme}
            isEmulation={isEmulation}
          />
          <PrivateRoute exact path="/documents" component={MyDocuments} />
          {/* <PrivateRoute exact path="/future" component={MyFuture} /> */}
          <PrivateRoute exact path="/accounts" component={MyAccounts} />

          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/overview"
            component={LwoOverview}
          />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/about-assure"
            component={AboutAssure}
          />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/consolidation-lwo"
            component={ConsolidationLwo}
          />
          <PrivateRoute exact path="/benefits/limited-waiver-opportunity/fee" component={LwoFee} />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/loan-information"
            component={LwoLoanInfo}
          />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/questionnaire"
            component={LwoQuestionnaire}
          />

          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/agreement"
            component={LwoAgreement}
          />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/employment-certification"
            component={LwoEmploymentCertification}
          />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/employment-certification/step-1"
            component={LwoEmploymentCertification}
          />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/employment-certification/step-2"
            component={LwoEmploymentCertification}
          />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/employment-certification/step-3"
            component={LwoEmploymentCertification}
          />

          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/complete"
            component={LwoComplete}
          />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/not-qualified"
            component={LwoNotQualified}
          />
          <PrivateRoute
            exact
            path="/benefits/limited-waiver-opportunity/confirmation"
            component={LwoFinalConfirmation}
          />
          {/* Benefits */}
          {permissions.showForgiveness && (
            <PrivateRoute exact path="/benefits/forgiveness/overview" component={MyForgiveness} />
          )}
          <PrivateRoute
            exact
            path="/benefits/forgiveness/forbearance-p1"
            component={ForbearancePhaseOne}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/forbearance-p2"
            component={ForbearancePhaseTwo}
          />

          <PrivateRoute exact path="/benefits/forgiveness/empcert" component={EmpCert} />
          <PrivateRoute exact path="/benefits/forgiveness/empcert/step-1" component={EmpCert} />
          <PrivateRoute exact path="/benefits/forgiveness/empcert/step-2" component={EmpCert} />
          <PrivateRoute exact path="/benefits/forgiveness/empcert/step-3" component={EmpCert} />

          <PrivateRoute exact path="/benefits/forgiveness/consolidate" component={Consolidation} />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/consolidate/step-1"
            component={Consolidation}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/consolidate/step-2"
            component={Consolidation}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/consolidate/step-3"
            component={Consolidation}
          />

          <PrivateRoute exact path="/benefits/forgiveness/idr" component={Idr} />
          <PrivateRoute exact path="/benefits/forgiveness/idr/step-1" component={Idr} />
          <PrivateRoute exact path="/benefits/forgiveness/idr/step-2" component={Idr} />
          <PrivateRoute exact path="/benefits/forgiveness/idr/step-3" component={Idr} />

          <PrivateRoute exact path="/benefits/forgiveness/welcome" component={Welcome} />
          <PrivateRoute exact path="/benefits/forgiveness/taxes" component={Taxes} />
          <PrivateRoute exact path="/benefits/forgiveness/complete" component={Complete} />

          <PrivateRoute
            exact
            path="/benefits/forgiveness/profile"
            component={ProfileVerification}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/questionnaire"
            component={RenewalQuestionnaire}
          />
          <PrivateRoute exact path="/benefits/forgiveness/nslds" component={NSLDSUpload} />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/renewal-extension"
            component={RenewalExtension}
          />
          <PrivateRoute exact path="/benefits/forgiveness/fee" component={FeeRS} />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/forbearance-rs"
            component={ForbearanceRS}
          />
          <PrivateRoute exact path="/benefits/forgiveness/empcert-ss" component={EmpCertSS} />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/empcert-ss/step-1"
            component={EmpCertSS}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/empcert-ss/step-2"
            component={EmpCertSS}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/empcert-ss/step-3"
            component={EmpCertSS}
          />
          <PrivateRoute exact path="/benefits/forgiveness/idr-ss" component={IDRSS} />
          <PrivateRoute exact path="/benefits/forgiveness/idr-ss/step-1" component={IDRSS} />
          <PrivateRoute exact path="/benefits/forgiveness/idr-ss/step-2" component={IDRSS} />
          <PrivateRoute exact path="/benefits/forgiveness/idr-ss/step-3" component={IDRSS} />

          <PrivateRoute exact path="/benefits/forgiveness/bts-review" component={BtsReview} />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/bts-questionnaire"
            component={BtsQuestionnaire}
          />

          {/*Start of Implementation FS Routes */}
          <PrivateRoute
            exact
            path="/benefits/forgiveness/forbearance-p1-fs"
            component={ForbearancePhaseOneFS}
          />
          <PrivateRoute exact path="/benefits/forgiveness/empcert-fs" component={EmpCertFS} />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/consolidate-fs"
            component={ConsolidationFS}
          />
          <PrivateRoute exact path="/benefits/forgiveness/repayment-app-fs" component={IDRFS} />

          <PrivateRoute
            exact
            path="/benefits/forgiveness/contribution-ss"
            component={ContributionSS}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/contribution-ss/step-1"
            component={ContributionSS}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/contribution-ss/step-2"
            component={ContributionSS}
          />

          <PrivateRoute
            exact
            path="/benefits/forgiveness/contribution-rsss"
            component={ContributionRSSS}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/contribution-rsss/step-1"
            component={ContributionRSSS}
          />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/contribution-rsss/step-2"
            component={ContributionRSSS}
          />

          <PrivateRoute
            exact
            path="/benefits/forgiveness/continuing-education"
            component={OHGraduation}
          />

          {/*Start of Renewal FS Routes */}
          <PrivateRoute exact path="/benefits/forgiveness/docs-needed" component={DocNeededRSFS} />
          <PrivateRoute exact path="/benefits/forgiveness/meeting" component={MeetingRSFS} />
          <PrivateRoute exact path="/benefits/forgiveness/idr-fs" component={IDRRSFS} />
          <PrivateRoute exact path="/benefits/forgiveness/review" component={AccountReviewRS} />
          <PrivateRoute
            exact
            path="/benefits/forgiveness/recertification"
            component={Recertification}
          />
          <PrivateRoute exact path="/benefits/forgiveness/emp-cert-fs" component={EmpCertRSFS} />

          {permissions.showSimplifi && (
            <PrivateRoute exact path="/benefits/simplifi" component={MySimpliFi} />
          )}
          {permissions.showRefinance && (
            <PrivateRoute exact path="/benefits/refinance" component={MyRefinance} />
          )}
          {permissions.showRetirement && (
            <PrivateRoute exact path="/benefits/retirement" component={MyRetirement} />
          )}
          {permissions.showContribution && (
            <PrivateRoute
              exact
              path="/benefits/contribution/overview"
              component={ContributionOverview}
            />
          )}
          {permissions.showContribution && (
            <PrivateRoute
              exact
              path="/benefits/contribution/inactive-contributions"
              component={InactiveContributions}
            />
          )}
          {permissions.showContribution && (
            <PrivateRoute
              exact
              path="/benefits/contribution/active-contributions"
              component={ActiveContributions}
            />
          )}

          {permissions.showIdentity && (
            <PrivateRoute exact path="/benefits/identity" component={MyIdentity} />
          )}
          {permissions.showStudentLoanDisabilityInsurance && (
            <PrivateRoute exact path="/benefits/insurance" component={MyInsurance} />
          )}

          <PrivateRoute exact path="/benefits/tuition/overview" component={MyTuition} />
          <PrivateRoute exact path="/benefits/tuition/institutions" component={MyInstitutions} />
          <PrivateRoute exact path="/benefits/tuition/terms" component={MyTerms} />
          <PrivateRoute exact path="/benefits/tuition/classes" component={MyClasses} />
          <PrivateRoute exact path="/benefits/tuition/history" component={MyReimbursementHistory} />

          {permissions.hasBenefitTools && (
            <PrivateRoute exact path="/tools/pretax-tool" component={MyPretaxTool} />
          )}

          {permissions.hasBenefitTools && (
            <PrivateRoute exact path="/tools/retirement-tool" component={MyRetirementTool} />
          )}

          {permissions.hasBenefitTools && (
            <PrivateRoute exact path="/tools/tax-calculator" component={TaxEstimatorTool} />
          )}

          {permissions.showPolaris && (
            <PrivateRoute exact path="/tools/plan-tool" component={PlanTool} />
          )}

          {/* Benefit Tools */}
          {/*permissions.showForgiveness && (
            <PrivateRoute exact path="/tools/modeler" component={MyBenefitModeler} />
          )*/}
          {permissions.showForgiveness && (
            <PrivateRoute exact path="/tools/taxtool" component={MyTaxTool} />
          )}
          {permissions.showContribution && (
            <PrivateRoute exact path="/tools/sla-comparison" component={MySLAComparison} />
          )}
          <PrivateRoute exact path="/tools/interestcomparison" component={MyInterestComparison} />

          {/* Profile and Settings */}
          <PrivateRoute exact path="/profile" component={MyProfile} />
          <PrivateRoute exact path="/settings" component={MySettings} />
          <PrivateRoute exact path="/closed" component={Closed} />
          <PrivateRoute exact path="/contact" component={Contact} />
          <PrivateRoute exact path="/update-password-auth" component={MyPasswordAuth} />
          <PrivateRoute exact path="/faq" component={Faq} />
          <PrivateRoute
            exact
            path="/my-security-questions"
            component={SecurityQuestionsDashboard}
          />

          {/* Enterprise/Standalone Empcert/Emplify */}
          <PrivateRoute exact path="/benefits/empcert/generate" component={EmpCertGen} />
          <PrivateRoute exact path="/benefits/empcert/submit" component={EmpCertSubmit} />
          <PrivateRoute exact path="/benefits/empcert/check" component={EmpCertCheck} />
          <PrivateRoute exact path="/benefits/empcert/complete" component={EmpCertComplete} />
          <PrivateRoute exact path="/benefits/empcert/correction" component={EmpCertCorrection} />

          {/* Enterprise/Fresh Start */}
          <PrivateRoute exact path="/benefits/freshstart/overview" component={FreshStartOverview} />
          <PrivateRoute exact path="/benefits/freshstart/contact" component={FreshStartContact} />
          <PrivateRoute exact path="/benefits/freshstart/awaiting" component={FreshStartAwaiting} />
          <PrivateRoute exact path="/benefits/freshstart/nslds" component={FreshStartNSLDS} />
          <PrivateRoute exact path="/benefits/freshstart/complete" component={FreshStartComplete} />

          {/* Enterprise/Educational Reengagement */}
          <PrivateRoute
            exact
            path="/benefits/educational-reengagement/overview"
            component={EducationalReengagementOverview}
          />

          {/* Enterprise/Reduced IDR Implementation */}
          <PrivateRoute exact path="/benefits/reducedidr/overview" component={ReducedIDROverview} />
          <PrivateRoute exact path="/benefits/reducedidr/welcome" component={ReducedIDRWelcome} />
          <PrivateRoute exact path="/benefits/reducedidr/idr" component={IdrReduced} />
          <PrivateRoute exact path="/benefits/reducedidr/idr/step-1" component={IdrReduced} />
          <PrivateRoute exact path="/benefits/reducedidr/idr/step-2" component={IdrReduced} />
          <PrivateRoute exact path="/benefits/reducedidr/idr/step-3" component={IdrReduced} />
          <PrivateRoute exact path="/benefits/reducedidr/complete" component={ReducedIDRComplete} />

          {/* Enterprise/Reduced IDR Renewal */}
          <PrivateRoute
            exact
            path="/benefits/reducedidr/idrrenewal"
            component={IdrReducedRenewal}
          />
          <PrivateRoute
            exact
            path="/benefits/reducedidr/idrrenewal/step-1"
            component={IdrReducedRenewal}
          />
          <PrivateRoute
            exact
            path="/benefits/reducedidr/idrrenewal/step-2"
            component={IdrReducedRenewal}
          />
          <PrivateRoute
            exact
            path="/benefits/reducedidr/idrrenewal/step-3"
            component={IdrReducedRenewal}
          />
          <PrivateRoute
            exact
            path="/benefits/reducedidr/completerenewal"
            component={IdrReducedRenewalComplete}
          />
          <PrivateRoute exact path="/plaid/overview" component={PlaidOverview} theme={theme} />
          <PrivateRoute
            exact
            path="/loan-repayments/overview"
            component={RepaymentOverview}
            theme={theme}
          />
          {/* Catch all/undefined routes and send back to error handler */}
          <PrivateRoute exact path="/" component={() => <Redirect to="/dashboard" />} />
          <Route children={() => <Redirect to="/error/404" />} />
        </Switch>
      </AnimationGroup>
    )}
  />
));

export default RouterDashboard;
